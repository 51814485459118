<template>
    <div class='searchcomment'>
        <el-row type='flex'>
            <el-col :span="6"></el-col>
            <el-col :span="12">
                <el-input  placeholder="请输入用户名" v-model='searchtext'></el-input>
            </el-col>
            <el-col :span="1"></el-col>
             <el-col :span="8">
                <el-button type='primary' @click='searchText'>查询</el-button>
                <el-button  type='info' @click='reset'>重置</el-button>
             </el-col>
             <el-col :span="5"></el-col>
        </el-row>
        <!-- 评论列表 -->
            <el-table
            :data="showData"
            style="width: 100%">
            <el-table-column
            prop="course_id"
            label="课程id"
            width="90" align="center">
            </el-table-column>
            <el-table-column
                prop="coursename"
                label="课程名称"
                width="120" align="center">
            </el-table-column>
            <el-table-column
                prop="username"
                label="用户名"
                width="120" align="center">
            </el-table-column>
                <el-table-column
                prop="content"
                label="评论信息"
                width="250" align="center">
            </el-table-column>
                <el-table-column
                prop="datetime"
                label="评论时间" align="center">
            </el-table-column>
                <el-table-column
                label="状态"  align="center">
                <template slot-scope="scope">
                    <el-tag type='success' v-if='scope.row.audit==1'>已发布</el-tag>
                    <el-tag type='info' v-if='scope.row.audit==0'>审核中</el-tag>
                    <el-tag type='danger' v-if='scope.row.audit==2'>未通过</el-tag>
                </template>
            </el-table-column>
                <el-table-column
                label="操作"  align="center">
                <template slot-scope="scope">
                    <el-button type='danger'  size='mini' @click='editdelete(scope.row.comments_id)' v-if='scope.row.audit==1'>删除</el-button>
                    <el-button type='success'  size='mini' @click='editpublish(scope.row.comments_id)' v-if='scope.row.audit!=1'>发布</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    data(){
        return{
            tableData:[],
            searchtext:'',
            showData:[]
        }
    },
    created(){
        this.getcomments(0)
    },
    methods:{
        getcomments(id){
            this.axios.post('https://dsvi.com:3010/management/getcomments',{
                 id
              })
              .then(res=>{
                  if(res.data.info==200){
                      this.tableData=res.data.data
                      this.showData=this.tableData
                  }
              })           
        },
        // 搜索相关
        searchText(){
            const _this=this
            this.showData=this.tableData.filter(item=>{
                return item.username==_this.searchtext
            })
        },
        // 重置
        reset(){
            this.showData=this.tableData
        },
        // 删除 
        editdelete(id){
            this.axios.post('https://dsvi.com:3010/management/deletecomment',{
                id,
            }).then(res=>{
                if(res.data.info==200){
                    this.getcomments(0)
                    this.$message.success('删除评论成功')
                }
            })
        },
        editpublish(id){
             this.axios.post('https://dsvi.com:3010/management/pushcomments',{
                id,
                type:0
            }).then(res=>{
                if(res.data.info==200){
                    this.getcomments(0)
                    this.$message.success('发表评论成功')
                }
            })
        }
    }
}
</script>
<style scoped>

</style>