<template>
	<el-main>
		<el-tabs>
		  <el-tab-pane>
		    <span slot="label"><i class="el-icon-date"></i> 评论审核</span>
				<!-- <commentslist></commentslist> -->
		  </el-tab-pane>
		 <el-tab-pane>
		   <span slot="label"><i class="el-icon-date"></i> 用户评论查询</span>
		   <!-- <searchcomment></searchcomment> -->
		 </el-tab-pane>
		</el-tabs>
	</el-main>
</template>

<script>
	import {mapGetters} from 'vuex'
	// import commentslist from './commentslist'
		// import searchcomment from './searchcomment'
	export default{
		components:{
			// commentslist,
			// searchcomment
		},
		computed: {
			...mapGetters([
				'allaudit'
			])
		},
		data(){
			return{
				
			}
		},
		methods:{
			auditresult(e,j){
				if(e == 0){
					
					this.$prompt('请输入你需要回复的信息如果没有可以留空', '通过', {
					         confirmButtonText: '确定',
					         cancelButtonText: '取消',
					       }).then(({ value }) => {
							   if(value){
									this.$store.dispatch('audit/inputAuditResults',[e,j,value])	
									this.$message({
									  type: 'success',
									  message: '操作成功'
									});
								}else{
									this.$store.dispatch('audit/inputAuditResults',[e,j])	
								}
					       }).catch(() => {
					         this.$message({
					           type: 'info',
					           message: '已取消'
					         });       
					       });
				}else if(e == 1){
					 this.$prompt('请输入违规缘由', '违规', {
					          confirmButtonText: '确定',
					          cancelButtonText: '取消',
					        }).then(({ value }) => {
							this.$store.dispatch('audit/inputAuditResults',[e,j,value])	
					        }).catch(() => {
					          this.$message({
					            type: 'info',
					            message: '已取消'
					          });       
					        });
				}
				
			}
		},
		mounted(){
			this.$store.dispatch('audit/getAllComments')
		}
	}
</script>

<style>
	.pinlun{
		list-style: none;
		padding-left: 5px;
		border-top: 2px solid #cccccc;
	}
</style>
