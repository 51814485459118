<template>
	<el-col :span="24" id="topUp">
		<el-col :span="24" class="pay_title">
			<span class="family-our pay_title_name">系统充值</span>
		</el-col>
		<el-col :span="24" style="padding: 10px 25px;">
			<span class="family-black" style="margin-right: 20px;color: #3e3e3e;" >{{iswlk()}}{{states.userinfo.mobile}} <span style="font-size: 12px;color: #1396b0;">({{states.userinfo.ds_name}})</span></span>
			<span class="family-black">账户余额：<span style="font-family:'agency fb';color: #EA740C;">￥{{states.userinfo.money}}元</span></span>
		</el-col>
		<el-col :span="24" >
			<el-col style="padding-left: 30px;padding-top: 10px;">充值金额</el-col>
			<el-col style="padding-left: 30px;padding-top: 10px;">
				<el-input placeholder="请输入内容" v-model.number="money" size="small" style="width: 280px;">
				    <template slot="append">元</template>
				</el-input>
			</el-col>
			<el-col style="padding-left: 30px;padding-top: 10px;font-size: 12px;">
				请输入充值金额，不支持小数，最低1元,单次最大限额2万元
			</el-col>
		</el-col>
		<el-col :span="24" style="padding: 15px;" v-if="topupUrl != ''">
			<div class="topupbox">
				<p class="topuptext">￥{{money}}元 微信收款码</p>
				<img :src="topupUrl" class="topupimg">
				<p class="topuptext">{{time(score)}}</p>
			</div>
		</el-col>
		
		<el-col :span="24" style="text-align: left;border-top: 1px solid #959595;position: absolute;bottom: 10px;padding: 15px 40px;">
			<el-button type="success" style="width: 170px;" @click="confirm(money)" v-if="topupUrl == ''">确认充值</el-button>
			<el-button type="primary" style="width: 170px;" @click="quxiao">关闭</el-button>

			<el-popover placement="top" width="460" v-model="visible">
			  <h3 style="text-align: center;">充值协议服务条款</h3>
			  <p style="font-size: 16px;font-weight: bold;margin: 0px;">条款一</p>
			  <p style="margin-top: 0px;">商标自助提交系统由顺名科技有限公司（以下简称“商标自助提交系统”）设立,可在商标自助提交系统及合作的第三方服务提供商处消费的，可反复充值的预付账户。商标自助提交系统余额不可提现及退款，不可透支，不计利息。办理挂失事宜请拨打13229493323.</p>
			  <p style="font-size: 16px;font-weight: bold;margin: 0px;">条款二</p>
			  <p style="margin-top: 0px;">商标自助提交系统在必要时会对用户关联的手机号码发起短信验证。</p>
			  <p style="font-size: 16px;font-weight: bold;margin: 0px;">条款三</p>
			  <p style="margin-top: 0px;">凡在中华人民共和国境内合法注册的企事业单位，机关，团体等（以下统称“单位”）及具有完全民事行为能力的境内外居民（一下统称“个人”）均可申请办理商标自助提交系统账号。申请办理，使用商标自助提交系统账户的单位和个人，均须遵守本管理规定的各项规定。</p>
			  <p style="font-size: 16px;font-weight: bold;margin: 0px;">条款四</p>
			  <p style="margin-top: 0px;">账户申请方式。单位或个人用户可以通过商标自助提交系统在线办理或拨打客服电话咨询。账号的用户名称为注册的手机号码。，申请人不能通过现金支付，应通过微信支付，支付宝支付等方式充值。</p>
			  <p style="font-size: 16px;font-weight: bold;margin: 0px;">条款五</p>
			  <p style="margin-top: 0px;">用户充值后，账户余额不能办理退款以及赠送部分的优惠卷均不能转移，转增。账户余额请您根据自己的消费情况充值，商标自助提交系统对充值的次数不设限制。</p>
			  <div style="text-align: center; margin: 0">
			    <el-button type="primary" size="mini" @click="visible = false">我已知道</el-button>
			  </div>
			  <el-button slot="reference" type="text" style="color: #0000FF;cursor: pointer;position: absolute;top: 28px;margin-left: 25px;text-decoration: underline;">充值协议</el-button>
			</el-popover>
			<p style="font-size: 12px;color: red;margin-bottom: 0px;">如有疑问，请在支付前联系平台 客服电话：400-037-0388</p>
			<!-- 温馨提示：支付后，如要退款，将扣除所支付款项的1%作为手续费。 -->
		</el-col>
	</el-col>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default{
		computed: {
			...mapGetters(['states'])
		},
		data(){
			return{
				visible:false,
				topupUrl:'',
				money:0,
				amount:[
					{score:100},
					{score:500},
					{score:1000},
					{score:2000},
					{score:5000},
					{score:10000},
				],
				timer:null,
				score:0,
				key:null
			}
		},
		mounted(){
			
		},
		methods:{
			time(value){
				let result = parseInt(value)
				let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
				let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
				let res = '';
				if(h !== '00') res += `${h}:`;
				if(m !== '00') res += `${m}:`;
				res += `${s}`;
				return res;
			},
			quxiao(){
				this.topupUrl = ''
				this.score = 0
				this.states.linepay = false
				this.money = 0
				clearInterval(this.timer);
			},
			// 获取收款码
			confirm(event){
				let reg = /^[1-9]\d*$/
				if(reg.test(event) || true){
					if(event <= 5000 && event >= 1){
						this.$store.dispatch('WxAddQr',{payType: 1,amount: this.money,uid: this.states.userinfo.id}).then(res=>{
							this.key = res.key
							this.topupUrl = 'data:image/jpeg;base64,'+res.data
							this.score = 300
							clearInterval(this.timer);
							this.timer = setInterval(this.detection,2000);
						})
					}else{
						this.$notify({title: '提示', message: '单笔充值不能超过5000元或小于1元',type: 'warning'});
					}
				}else{
					this.$notify({title: '提示', message: '请输入正确的金额',type: 'warning'});
				}
			},
			// 支付回调检测
			detection(){
				this.score --
				if(this.score == 0){
					clearInterval(this.timer);
					this.quxiao()
					this.$notify.info({title: '消息',message: '收款码已失效，请重新生成！'});
				}else{
					if(this.score%2 == 1){
						this.$store.dispatch('PayListen', {uid:this.states.userinfo.id,key:this.key,money:this.states.userinfo.money}).then(res=>{
							if(res.code == 200){
								this.states.userinfo = res.data
								localStorage.setItem('userinfo', JSON.stringify(res.data));
								this.$store.dispatch('OTHERINFO')   // 用户额外信息
								clearInterval(this.timer);
								this.quxiao()
								this.$notify({title: '提示',message: '充值成功，余额已到账！',type: 'success',duration: 0});
							}else{
								console.log(res.message)
							}
						})
					}
				}
				if(!this.key){
					clearInterval(this.timer);
					this.quxiao()
					this.$notify.info({title: '消息',message: '收款码异常，请重新生成！'});
				}
			},
			iswlk(){
				let now = new Date(),hour = now.getHours()
				if(hour < 6){
					return "凌晨好！"
				}else if (hour < 9){
					return "早上好！"
				}else if (hour < 12){
					return "上午好！"
				}else if (hour < 14){
					return "中午好！"
				}else if (hour < 17){
					return "下午好！"
				}else if (hour < 19){
					return "傍晚好！"
				}else if (hour < 22){
					return "晚上好！"
				}else {
					return "夜里好！"
				}

			}
		},
		destroyed() {
			console.log("关闭")
			clearInterval(this.timer);
			this.score = 0
		},
	}
</script>

<style>
	.topupout{
		float: right;
	}
	.topupout:hover{
		text-decoration: underline;
		color: #0000FF;
		cursor: pointer;
	}
	.moneyBtn{
		text-align: center;
		width: 100%;
		font-family:'agency fb';
	}

	.topupimg{
		width: 200px;
		height: 200px;
		margin-top: 30px;
		margin-left: 29px;
		border-radius: 15px;
		border: 2px solid #FAE7D5;
		opacity: 1;
	}
	.topuptext {
		color: #FFFFFF;
		margin: 0;
		position: absolute;
		text-align: center;
		width: 265px;
		padding-top: 3px;
		font-size: 15px;
	}
	.topupbox {
		margin-left: auto;
		margin-right: auto;
		width: 265px;
		height: 265px;
		background-color: #EF6409;
	}
	
	#topUp .pay_title{
		height: 50px;
		background-color: #FFFFFF;
		line-height: 50px;
		padding:0px 15px;
		border-bottom: 1px solid #eee;
	}
	#topUp .pay_title_name{
		font-size: 24px;
		font-weight: bold;
		margin-right: 20px;
	}
</style>
