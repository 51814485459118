<template>
	<el-row id="blog"  v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)">
		<el-col :span="6" v-for="(item,index) in states.blog" :key="index" class="box" @click.native="OpenBlog(item,index)" v-if="JSON.stringify(states.playSoutce) == '{}'">
			<el-col :span="24" style="box-shadow: 0px 2px 5px rgba(9, 9, 9, 0.5);position: relative;">
				<div class="demo-image__placeholder">
				  <div class="block"><el-image :src="item.posters" style="height: 138px;width: 100%;"></el-image></div>
				</div>
				<h5>{{item.title}}</h5>
				<p class="studyers1">
					<span class="permis">
						<span v-for="(list,idx) in item.blogTag" :key="idx" style="padding-right: 10px;">{{list}}</span>
					</span>
				</p>
				<p class="studyers">
					<span style="color: #acacac; ">播放量：{{item.viewCounts}} · {{$timesDate(item.dateTime)}}</span>
				</p>
			</el-col>
		</el-col>
		
		<el-col :span="24" v-if="JSON.stringify(states.playSoutce) != '{}'">
			<el-col :span="17">
				<play v-if="show"></play>
				<el-alert :title="'上次播放: '+timeFun(states.recordPlayBlog)" type="success" v-if="this.states.recordPlayBlog && this.states.recordPlayBlog != 'undefined'" class="tis" :closable="false"></el-alert>
				<el-col :span="24">
					<h4 style="color: #e6e6e6;">
						{{states.playSoutce.title}}
						<el-button type='primary' @click="collection(states.playSoutce)" size="mini" style="float: right;" v-if="check(states.playSoutce) == 1">收藏</el-button>
						<el-button type='primary' @click="deleteCollection(states.playSoutce)" size="mini" style="float: right;" v-if="check(states.playSoutce) == 2">取消收藏</el-button>
					</h4> 
					<p class="studyers">
						<span style="color: #666;">播放量：{{states.playSoutce.viewCounts}} · {{$timesDate(states.playSoutce.dateTime)}}</span>
						<span class="permis">
							<el-tag size="mini" type="text"title="免费公开课" v-for="(list,idx) in states.playSoutce.blogTag" :key="idx" style="margin-left: 5px;">{{list}}</el-tag>
						</span>
					</p>
				</el-col>
			</el-col>
			<el-col :span="7" style="height:calc(100vh - 110px);overflow: auto;" id="blogList">
				
				<el-col :span="24" v-for="(item,index) in states.blog" :key="index" class="box" @click.native="OpenBlog(item,index)" :id="'item-'+index">
					<el-col :span="24" style="box-shadow: 0px 2px 5px rgba(9, 9, 9, 0.5);position: relative;">
						<div class="demo-image__placeholder">
						  <div class="block"><el-image :src="item.posters" style="height: 138px;width: 100%;"></el-image></div>
						</div>
						<h5>{{item.title}}</h5>
						<p class="studyers1">
							<span class="permis">
								<span v-for="(list,idx) in item.blogTag" :key="idx" style="padding-right: 10px;">{{list}}</span>
							</span>
						</p>
						<p class="studyers">
							<span style="color: #acacac; ">播放量：{{item.viewCounts}} · {{$timesDate(item.dateTime)}}</span>
						</p>
					</el-col>
				</el-col>
				
				
			</el-col>
		</el-col>
	</el-row>
</template>

<script>
	import play from '../play'; // 播放器
	import {timestampToDate} from '@/utils/fun'
	import {mapGetters} from 'vuex'
	export default {
		components: {
			play,
		},
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				show:true,
				loading:false
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init(){
				this.loading = true
				this.$store.dispatch('allblog').then(res=>{
					this.states.blog = res.data
					// 判断是否为收藏跳转
					if(JSON.stringify(this.states.favor) != '{}'){
						this.states.blog.forEach((item,index)=>{
							if(item.blogID == this.states.favor.blogID){
								this.OpenBlog(item,index)
								this.states.favor = {}
							}
						})
					}else{
						// 判断是否为搜索跳转
						if(JSON.stringify(this.states.searchB) != '{}'){
							this.states.blog.forEach((item,index)=>{
								if(item.blogID == this.states.searchB.id){
									this.OpenBlog(item,index)
									this.states.searchB = {}
								}
							})
						}else{
							this.OpenBlog(this.states.blog[0],0)
						}
					}
					
					this.loading = false
				})
			},
			// 取消收藏
			deleteCollection(e){
				this.$store.dispatch('delCollect',{uid: this.states.userinfo.id,cid: e.blogID,type:2}).then(res=>{
					if(res.code == '200'){
						this.states.mycuorse = res.data
					}
				})
			},
			// Blog收藏
			collection(e) {
				this.$store.dispatch('addCollect',{uid: this.states.userinfo.id,cid: e.blogID,type:2}).then(res=>{
					if(res.code == '200'){
						this.states.mycuorse = res.data
					}
				})
			},
			check(e){
				if(JSON.stringify(this.states.userinfo) != '{}'){
					let arr = []
					if(this.states.mycuorse.length > 0){
						this.states.mycuorse.forEach(item=>{
							if(item.collect_id == e.blogID && item.type == 2){arr.push(item)}
						})
						return arr.length > 0?'2':'1'
					}else{
						return '1'
					}
				}else{
					return '3'
				}
			},
			// 秒转时间
			timeFun(e){
				let result = parseInt(e)
				let h = Math.floor(result / 3600) < 10 ?'0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				let m = Math.floor((result / 60 % 60)) < 10 ?'0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
				let s = Math.floor((result % 60)) < 10 ?'0' + Math.floor((result % 60)) : Math.floor((result % 60));
				let res ='';
				if(h !=='00'){
					res += `${h}:`
				}
				res += `${m}:${s}`
				return res;
			},
			OpenBlog(e,index){
				this.states.playSoutce = e
				this.show = false
				setTimeout(() =>{
					// 获取滚动容器的 DOM 元素
					let blogList = document.getElementById('blogList');
					// 获取要滚动到的元素的 DOM 元素
					let targetElement = document.getElementById('item-' + (index));
					// 计算要滚动的距离
					let scrollTop = targetElement.offsetTop - blogList.offsetTop;
					// 使用滚动容器的 scrollTo 方法实现滚动效果
					blogList.scrollTo({
					  top: scrollTop,
					  behavior: 'smooth'
					});
				   this.show = true
				},100);
			},
			timestampToDate(e){
				return timestampToDate(e*1000)
			},
		}
	}
</script>

<style>
	#blog {
		padding: 15px;
		height: calc(100vh - 72px);
	}
	#blog h5{
		height: 20px;
		line-height: 20px;
		margin: 0px;
		font-size: 15px;
		padding: 0px 10px;
		padding-top: 5px;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #e5e5e5;
		display: -webkit-box;
		-webkit-line-clamp:2;
		-webkit-box-orient: vertical;
	}
	#blog h5:hover{
		color: #FF6716;
	}
	#blog .box{
		padding: 15px;
		position: relative;
		cursor: pointer;
	}
	#blog .permis{
		float: right;
	}

	#blog .studyers{
		height: 18px;
		line-height: 18px;
		margin: 0px;
		padding: 5px 10px;
		font-size: 12px;
		color: #666;
	}
	#blog .studyers1{
		position: absolute;
		font-size: 12px;
		color: #ececec;
		bottom: 44px;
		right: 0px;
		height: 20px;
		width: 100%;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7));
		text-shadow: 2px 5px 10px rgba(0, 0, 0, 1);
	}
	#blog .shelvestime{
		margin: 0px;
		padding: 0px 10px;
		font-size: 12px;
		text-align: right;
		color: #73767A;
	}

</style>
