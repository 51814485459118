<template>
	<el-row id="history" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)">
		<el-col :span="24" style="height: 25px;line-height: 25px;font-size: 16px;color: #d6d6d6;">
			<i class="el-icon-alarm-clock" style="color: peru;font-weight: bold;font-size: 17px;"></i> 历史记录 
			<el-button type="danger" size="mini" style="float: right;margin-right: 15px;" @click="DelItem({latel_id:0},2)" v-if="logArr.length > 0">清空</el-button>
		</el-col>
		<el-col :span="24" style="margin-top: 30px;">
			<el-timeline>
			    <el-timeline-item placement="top"  v-for="(item,index) in logArr" :key="index">
					<template slot-scope="timestamp">
						<p style="color: #666;position: absolute;margin-top: -25px;color: #fff;">
							{{timestampToDate(item.eventTime)}} <i class="el-icon-delete" @click="DelItem(item,1)" style="color: #fff;"></i>
						</p> 
						<el-card class="box-card" style="margin-right: 20px;cursor: pointer;" @click.native="jumpPage(item)">
							<el-col :span="4">
								<el-image style="height: 50px;margin-top: 5px;margin-left: 5px;" :src="item.cover" fit="scale-down"></el-image>
							</el-col>
							<el-col :span="20" style="height: 50px;">
								<el-tag type="success" v-if="item.type == 1" style="float: right;margin: 14px;">大师课程</el-tag>
								<el-tag type="primary" v-if="item.type == 2"  style="float: right;margin: 14px;">BLOG</el-tag>
								<p style="margin: 0px;line-height: 45px;padding-left: 15px;">{{item.title}}</p>
							</el-col>
						</el-card> 
					</template>
			    </el-timeline-item>
			  </el-timeline>
		</el-col>
	</el-row>
</template>

<script>
	import {timestampToTimes} from '@/utils/fun'
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				logArr:[],
				loading:false
			}
		},
		mounted() {
			this.init()
		},

		methods: {
			timestampToDate(e){
				return timestampToTimes(e*1000)
			},
			init(){
				this.loading = true
				this.$store.dispatch('GetMyLogPlay',{uid: this.states.userinfo.id}).then(res=>{
					if(res.code == '200'){
						this.logArr = res.data
						this.loading = false
					}
				})
			},
			// 跳转
			jumpPage(e){
				if(e.type == 1){
					// 课程跳转
					this.states.ispermis = false
					this.$store.dispatch('courseInfo',{course_id:e.viewID,uid:this.states.userinfo.id}).then(res=>{
						if(res.code == 200){
							this.states.playerSource = {course:res.course,chapter:this.OCURSECONTENT(res.data)}
							this.states.playerduring = this.OCURSECONTENT(res.data).chapter[0][0]
							this.states.ispermis = res.ispermis
							this.states.selected = 999
						}else{
							this.$notify({ title: '温馨提醒',message: '您还没获取学习本门课程的资格呢！',type: 'warning'});
						}
					})
				}else{
					this.$store.dispatch('allblog').then(res=>{
						this.states.blog = res.data
						res.data.forEach(item=>{
							if(item.blogID == e.viewID){
								this.states.playSoutce = item
								this.states.selected = 2
							}
						})
					})
				}
			},
			// 删除
			DelItem(event,key){
				if(key == 1){
					this.$store.dispatch('DelMyLogPlay',{id:event.latel_id,uid: this.states.userinfo.id,key:key}).then(res=>{
						this.init()
					})   
				}else{
					this.$confirm('是否清空播放记录？', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
					      this.$store.dispatch('DelMyLogPlay',{id:event.latel_id,uid: this.states.userinfo.id,key:key}).then(res=>{
					      	this.init()
					      })   
					}).catch(() => {
					  this.$notify.info({ title: '提示',message: '已取消'});          
					});
				}
			},
			// 格式化课程章节表
			OCURSECONTENT(e){
				let arraysay = [],arr = [],cat = [],arrine = [],obj = {}
				e.forEach(item=>{
					cat.push(item.chapter)
				})
				arrine = Array.from(new Set(cat))
				//播放时长数组
				let timelengths = [],o = 0;
				arrine.forEach(item=>{
					e.forEach(list=>{
						if(list.chapter == item){
							list.show = false
							timelengths.push(list.timelength.split(/[分秒]/))
							arr.push(list)
							o ++;
						}
					})
					arraysay.push(arr)
					arr = []
				})
				let suour = 0
				//计算章节数  
				obj.chapternumber = arraysay.length
				obj.sectionnumber = o
				//计算时长总和
				timelengths.forEach(item=>{
					suour += parseInt(item[0]*60) + parseInt(item[1]===''?0:item[1])	
				})
				suour = suour/60/60
				// 总时长
				obj.timezong = suour.toFixed(2)
				obj.chapter = arraysay
				return obj
			},
		}
	}
</script>

<style>
	#history {
		padding: 5px 15px;
		height: calc(100vh - 72px);
	}
	#history .el-card__body{
		padding: 0px;
	}
</style>
