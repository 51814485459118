<template>
	<el-row id="recommend"  v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)">
		<!-- :xs="24" :sm="12" :md="8" :lg="6" :xl="6" -->
		<el-col :span="6" v-for="(item,index) in courseList" :key="index" class="box" @click.native="OpenCourese(item)">
			<el-col :span="24" style="box-shadow: 0px 2px 5px rgba(9, 9, 9, 0.5);">
				<div class="demo-image__placeholder">
				    <div class="block">
					  <el-image :src="item.thumbnail" fit="contain"></el-image>
					</div>
					<span class="permis">
						<span class="tags">
							<span style="color: #11cbf0;" v-if="item.permis == 0" title="免费公开课">公开课程</span>
							<span style="color: #04ce08;" v-if="item.permis == 1" title="线上课程">线上课程</span>
							<span style="color: #ff521e;" v-if="item.permis == 2" title="社群学员专享">社群课程</span>
							<span style="color: #ffa508;" v-if="item.permis == 3" title="付费课程">付费课程</span>
						</span>
					</span>
				</div>
				<h5 style="padding-left: 10px;">{{item.coursename}}</h5>
				<p style="margin: 0px;height: 40px;">
					<el-tooltip class="item">
						<div slot="content" style="width: 200px;">{{item.introduce}}</div>
						<p class="introduce">{{item.introduce}}</p>
					</el-tooltip>
				</p>
				<p class="studyers">
					<span style="color: #cfcfcf; ">学习人数：{{item.studyers}} 人  · {{$timesDate(item.shelvestime)}}</span>
				</p>
			</el-col>
		</el-col>
	</el-row>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				courseList:[],
				loading:false
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init(){
				this.loading = true
				this.$store.dispatch('allcourse').then(res=>{
					this.courseList = res.data
					this.loading = false
				})
			},
			OpenCourese(e){
				if(JSON.stringify(this.states.userinfo) != '{}'){
					this.states.ispermis = false
					this.$store.dispatch('courseInfo',{course_id:e.course_id,uid:this.states.userinfo.id}).then(res=>{
						if(res.code == 200){
							this.states.playerSource = {course:e,chapter:this.$OCURSECONTENT(res.data)}
							this.states.playerduring = this.$OCURSECONTENT(res.data).chapter[0][0]
							this.states.ispermis = res.ispermis
							this.states.selected = 999
						}else{
							this.$notify({ title: '温馨提醒',message: '您还没获取学习本门课程的资格呢！',type: 'warning'});
						}
					})
				}else{
					this.$notify({ title: '温馨提醒',message: '请您先登录！',type: 'warning'});
				}
			}
		}
	}
</script>

<style>
	#recommend {
		padding:0px 15px;
		height: calc(100vh - 72px);
	}
	#recommend h5{
		width: 100%;
		margin: 0px;
		padding: 4px;
		color: #dcdcdc;
		font-size: 14px;
	}
	#recommend h5:hover{
		color: #FF6716;
	}
	#recommend .box{
		padding: 10px;
		cursor: pointer;
		
	}
	#recommend .permis{
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
	#recommend .introduce{
		margin: 5px;
		font-size: 12px;
		padding: 0px 5px;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #e1e1e1;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	#recommend .studyers{
		line-height: 22px;
		margin: 0px;
		padding: 5px 10px;
		padding-bottom: 10px;
		font-size: 12px;
		color: #e1e1e1;
	}

	#recommend .demo-image__placeholder{
		position: relative;
	}
	#recommend .tags {
		width: 64px;
		height: 23px;
		line-height: 23px;
		text-align: center;
		background-color: #161823;
		font-size: 12px;
		display: inline-block;
		border-radius: 8px;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5); 
		opacity: .9;
	}
</style>
