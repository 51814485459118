<template>
	<el-row :style="'padding: 3px 15px;background-color: #ffffff;height:'+(states.Height-72)+'px'" v-loading="loading" element-loading-text="拼命加载中">
		 <el-tabs v-model="activeName">
		    <el-tab-pane label="订单" name="order">
				<el-table :data="balance.slice((Page-1)*pagesize,Page*pagesize)" size="mini" :height="states.Height-165">
					<el-table-column prop="orderNumber" label="订单号" width="150" align="center"></el-table-column>
					<el-table-column label="类型" width="150" align="center">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.type == 1" type="success">充值</el-tag>
							<el-tag v-if="scope.row.type == 2" type="warning">课程</el-tag>
							<el-tag v-if="scope.row.type == 3" type="danger">认证</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="进出账"  width="130">
						<template slot-scope="scope">
							{{scope.row.calculate == 1?'进账':'出账'}}
						</template>
					</el-table-column>
					<el-table-column prop="amount" label="金额" width="130">
						<template slot-scope="scope">
							<span>
								{{scope.row.calculate == 1?'+':'-'}}{{scope.row.amount}} 元
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="state" label="状态" width="130">
						<template slot-scope="scope">
							<span v-if="scope.row.type == 1" :style="scope.row.state == 1?'color: #009600':'color: #de1a08'">
								{{scope.row.state == 0?'未付款':'已付款'}} 
							</span>
							<span v-else :style="scope.row.state == 1?'color: #009600':'color: #de1a08'">
								{{scope.row.state == 0?'未支付':'付款成功'}}
							</span>
						</template>
					</el-table-column>
					<el-table-column label="创建时间">
						<template slot-scope="scope">{{Time(scope.row.createTime*1000)}}</template>
					</el-table-column>
					<el-table-column label="完成时间">
						<template slot-scope="scope">{{Time(scope.row.endtime*1000)}}</template>
					</el-table-column>
					<el-table-column label="操作栏">
						<template slot-scope="scope">
							<el-button type="primary" size="mini">详情</el-button>
							<el-button type="danger" size="mini" v-if="scope.row.state != 1" @click="rdel(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-col :span="24" style="text-align: center;">
					<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="Page" :page-sizes="[5,10,20,50]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="balance.length"></el-pagination>
				</el-col>
			</el-tab-pane>
		    <el-tab-pane label="流水记录" name="details">
				<el-table :data="details" size="mini">
					<el-table-column prop="creditNo" label="流水号"></el-table-column>
					<el-table-column prop="orderNumber" label="订单号"></el-table-column>
					<el-table-column label="流动金额">
						<template slot-scope="scope">{{scope.row.type == 1?'+':'-'}}{{scope.row.amount}} 元</template>
					</el-table-column>
					<el-table-column label="余额">
						<template slot-scope="scope">{{scope.row.money}} 元</template>
					</el-table-column>
					<el-table-column prop="source" label="备注"></el-table-column>
					<el-table-column label="生成时间">
						<template slot-scope="scope">{{Time(scope.row.creationtime*1000)}}</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		    <el-tab-pane label="充值记录" name="pay">
				<el-table :data="pay" size="mini">
					<el-table-column prop="orderNumber" label="订单号"></el-table-column>
					<el-table-column prop="pay_officeid" label="微信凭证"></el-table-column>
					<el-table-column label="充值金额">
						<template slot-scope="scope">{{scope.row.pay_txnamt}} 元</template>
					</el-table-column>
					<el-table-column label="商家">
						<template slot-scope="scope">
							{{scope.row.pay_businessid == '800595000017940'?'顺名科技有限公司':''}}
						</template>
					</el-table-column>
					<el-table-column label="支付时间">
						<template slot-scope="scope">{{Time(scope.row.datatime*1000)}}</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		  
		  </el-tabs>
		
		
	</el-row>
</template>

<script>
	import {timestampToTimes} from '@/utils/fun'
	import {
		mapGetters
	} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				activeName:'order',
				balance:[],
				details:[],
				pay:[],
				Page: 1,
				pagesize: 10,
				loading:false,
			}
		},
		mounted() {
			this.init()
		},

		methods: {
			init(){
				this.loading = true
				this.$store.dispatch('Balance', {uid: this.states.userinfo.id}).then(res => {
					let arr = [],arr1 = []
					res.data.balance.forEach(item=>{
						if(item.state == 0){
							arr.push(item)
						}else{
							arr1.push(item)
						}
					})
					this.balance = arr.concat(arr1)
					this.details = res.data.details
					this.pay = res.data.pay
					this.loading = false
				})
			},
			rdel(e){
				this.$confirm('此操作将永久删除该订单, 是否继续?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
							this.$store.dispatch('DelOrder', {
								order_id:e.order_id,
								usid:e.uid
							}).then(res=>{
								this.init()
							})
				        }).catch(() => {
				          this.$message({
				            type: 'info',
				            message: '已取消删除'
				          });          
				        });
			},
			Time(e){
				if(e){
					return timestampToTimes(e)
				}else{
					return '未完成'
				}
				
			},
			SizeChange(val) {
				this.pagesize = val
			},
			CurrentChange(val) {
				this.Page = val
			},
		}
	}
</script>

<style>

</style>
