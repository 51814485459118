<template>
	<el-row style="background-color: #161823;" id="player">
		<el-col :span="17">
				<!-- 视屏播放 -->
				<div class='course-left-player' ref="video" :style="vhight != 0?'height:'+vhight+'px':''">
					<coursevueplay v-if="states.PlayerShow" ref="playOn"></coursevueplay>
					<el-alert :title="'上次播放: '+timeFun(states.recordPlay)" type="success" v-if="this.states.recordPlay && this.states.recordPlay != 'undefined'" class="tis" :closable="false"></el-alert>    
					<!-- <span class="tiss">大师品牌 DSVI.COM</span> -->
				</div>
				<!-- 说明 -->
				<div class='course-left-explain'>
					<h5>{{states.playerduring.chapter}}章{{states.playerduring.section}}节-{{states.playerduring.abstract}}
						<el-button type='primary' @click="collection(states.playerSource)" size="mini" style="float: right;" v-if="check(states.playerSource) == 1">收藏</el-button>
						<el-button type='primary' @click="deleteCollection(states.playerSource)" size="mini" style="float: right;" v-if="check(states.playerSource) == 2">取消收藏</el-button>
					</h5>
					<p class="introduce">{{states.playerduring.instructions}} {{states.playerduring.sectionsay}}</p>
					<p class="studyers" style="margin-bottom: 5px;">
						<span style="color: #666;">
							学习人数：{{states.playerSource.course.studyers}} 人 · {{$timesDate(states.playerSource.course.shelvestime)}}
						</span>
						<span class="permis">
							<span style="color: #11cbf0;" v-if="states.playerSource.course.permis == 0" title="免费公开课">公开课程</span>
							<span style="color: #04ce08;" v-if="states.playerSource.course.permis == 1" title="线上课程">线上课程</span>
							<span style="color: #ff521e;" v-if="states.playerSource.course.permis == 2" title="社群学员专享">社群课程</span>
							<span style="color: #ffa508;" v-if="states.playerSource.course.permis == 3" title="付费课程">付费课程</span>
						</span>
					</p>
				</div>
				<!-- 用户评论 -->
				<div style="padding: 0px 10px;">
					<comments></comments>
				</div>
		</el-col>
		<el-col :span="7">
			<!-- 右侧 -->
				<!-- 列表 -->
				<h4 style="margin-bottom: 0px;color: #fff;">播放列表</h4>
				<div class="course-right-director">
					<!-- 章 -->
					<el-collapse v-for="(item,index) in states.playerSource.chapter.chapter" :key="index"  v-model="chapater">
					  <el-collapse-item :title="'第'+(index+1)+'章'" :name="String(index)">
							<div v-for="(item1,index1) in item" :key="index1" class="course-right-director-msg" @click="courseupdata(item1)">
							    <span style="color:#212529;font-size:12px">{{index1+1}}. {{item1.abstract}} </span>
								<i size="mini" class="el-icon-video-play playlien"></i>
								<span style="color:#adb5bd;font-size:12px;float: right;margin-right: 3px;">{{item1.timelength}}</span>
							</div>
					  </el-collapse-item>
					</el-collapse>
				</div>
				<!-- 课程介绍 -->
				<h4 style="margin-bottom: 0px;color: #fff;">介绍</h4>
				<div class='course-right-recommend'>
					<div class="demo-image__placeholder">
						<el-image :src="states.playerSource.course.thumbnail"></el-image>
					</div>
					<h5 style="margin: 0px;padding: 5px 10px;color: #fff;">{{states.playerSource.course.coursename}}</h5>
					<p class="introduce">{{states.playerSource.course.introduce}}</p>
					<p class="studyers1">
						{{states.playerSource.chapter.chapternumber}}章 
						{{states.playerSource.chapter.sectionnumber}}节 
						总时长：{{states.playerSource.chapter.timezong}}小时
					</p>
					<p class="studyers">
						<span style="color: #b3b3b3; ">学习人数：{{states.playerSource.course.studyers}} 人 · {{$timesDate(states.playerSource.course.shelvestime)}}</span>
						<span class="permis">
							<span style="color: #11cbf0;" v-if="states.playerSource.course.permis == 0" title="免费公开课">公开课程</span>
							<span style="color: #04ce08;" v-if="states.playerSource.course.permis == 1" title="线上课程">线上课程</span>
							<span style="color: #ff521e;" v-if="states.playerSource.course.permis == 2" title="社群学员专享">社群课程</span>
							<span style="color: #ffa508;" v-if="states.playerSource.course.permis == 3" title="付费课程">付费课程</span>
						</span>
					</p>
					<div v-if="states.playerSource.course.permis == 3 && !$store.state.cousrshow">
						<p class="price" v-if="!states.ispermis">
							<span style="color:#e5eff9;font-size:15px"> 课程售价 </span>
							<span class="amount"><del>{{(states.playerSource.course.price * 2.4).toFixed(2)}} ￥</del>
							<span style="color: #efa83e; "> {{states.playerSource.course.price.toFixed(2)}}￥</span></span>
							<el-button @click="moneypay(states.playerSource.course)" type="primary" size="mini" style="float: right;">立即购买</el-button>
						</p>
						<p class="price" v-if="states.ispermis">
							<span style="color:#61bd57;font-size:15px">已购买 </span>
						</p>
					</div>
				</div>
		</el-col>	


		
		<el-dialog :visible.sync="coursePay" :before-close="payQrClose" custom-class="payQrStyle" :show-close="false">
			<el-row v-if="coursePay">
				<p class="OrderTitle">课程订单支付</p>
				<p class="orderitem">账号：{{states.userinfo.mobile}}</p>
				<p class="orderitem">余额：<span style="color: #00abfa;">￥{{states.userinfo.money}} 元</span></p>
				<p class="orderitem">金额：<span style="color: #00abfa;">￥{{course.price.toFixed(2)}} 元</span></p>
				<p class="orderitem">商品：<span style="color: #00abfa;">{{course.coursename}}</span></p>
			</el-row>
			<p  v-if="states.userinfo.money < course.price" class="yuebuz">余额不足,请先充值！</p>
			<p style="padding-left: 35px;margin-top:10px;">
				<el-button type="primary" size="mini" @click="states.linepay = true" v-if="states.userinfo.money < course.price">在线充值</el-button>
				<el-button  v-if="states.userinfo.money > course.price" type="success" size="mini" @click="PayIng(course)" :loading="lock">{{lock?'正在支付':'支付'}}</el-button>
			</p>
			<img :src="course.posters" style="position: absolute;height: 128px;right: 30px;top: 85px;">
			<el-button type="text" @click="off()" style="position: absolute;right: 30px;top: 15px;color: #fff;text-decoration: underline;">关闭支付</el-button>
			<el-dialog :visible.sync="states.linepay" append-to-body custom-class="line-topup" :before-close="fun" :show-close="false">
				<topup></topup>
			</el-dialog>
		</el-dialog>
		
		
		
	</el-row>
</template>

<script>
	import topup from '../topup' 				  // 充值
	import {timestampToDate} from '@/utils/fun'
	import {mapGetters} from 'vuex'
	import coursevueplay from './coursevueplay'; // 播放器
	import comments from './comments'; 			 // 评论
	export default {
		computed: {
			...mapGetters(['states'])
		},
		components: {
			topup,
			coursevueplay,
			comments
		},
		data() {
			return {
				showa: false,  		// 判断是否需要权限
				chapater:'0',		// 默认章节打开
				vhight:0,
				coursePay:false,	// 购买课程窗口
				lock:false,
				course:{},			// 课程信息
			}
		},
		mounted() {
			
		},
		methods: {
			off(){
				this.coursePay = false
				this.course = {}
			},
			payQrClose(){},
			fun(){},
			// 秒转时间
			timeFun(e){
				let result = parseInt(e)
				let h = Math.floor(result / 3600) < 10 ?'0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				let m = Math.floor((result / 60 % 60)) < 10 ?'0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
				let s = Math.floor((result % 60)) < 10 ?'0' + Math.floor((result % 60)) : Math.floor((result % 60));
				let res ='';
				if(h !=='00'){
					res += `${h}:`
				}
				res += `${m}:${s}`
				return res;
			},
			// 检测是否收藏
			check(e){
				if(JSON.stringify(this.states.userinfo) != '{}'){
					let arr = []
					if(this.states.mycuorse.length > 0){
						this.states.mycuorse.forEach(item=>{
							if(item.collect_id == e.course.course_id && item.type == 1){arr.push(item)}
						})
						return arr.length > 0?'2':'1'
					}else{
						return '1'
					}
				}else{
					return '3'
				}
			},
			timestampToDate(e){
				return timestampToDate(e*1000)
			},
			// 支付
			PayIng(e){
				if (this.states.userinfo.money > e.price) {
					// 扣除余额 升级账号
					this.$store.dispatch('PayMember',{uid:this.states.userinfo.id,course_id:e.course_id,key:2,price:e.price}).then(res=>{
						this.states.userinfo = res.data
						if(res.role){
							this.states.userinfo.role = 2
						}
						localStorage.setItem('userinfo', JSON.stringify(res.data));
						this.$store.dispatch('OTHERINFO')   // 用户额外信息
						this.states.ispermis = true
						this.coursePay = false
						this.lock = false
					})
				}
			},
			// 购买课程
			moneypay(e) {
					this.course = e
					this.coursePay = true
			},
			// 取消收藏
			deleteCollection(e){
				this.$store.dispatch('delCollect',{uid: this.states.userinfo.id,cid: e.course.course_id,type:1}).then(res=>{
					if(res.code == '200'){
						this.states.mycuorse = res.data
					}
				})
			},
			// 课程收藏
			collection(e) {
				this.$store.dispatch('addCollect',{uid: this.states.userinfo.id,cid: e.course.course_id,type:1}).then(res=>{
					if(res.code == '200'){
						this.states.mycuorse = res.data
					}
				})
			},
			// 播放
			courseupdata(e) {
				this.states.PlayerShow = false
				this.states.playerduring = e
				this.vhight = this.$refs.video.offsetHeight
				setTimeout(() =>{
				    this.$nextTick(() => {
				    	this.states.PlayerShow = true
						setTimeout(() =>{
							this.$refs.playOn.playerOn();
						},200);
				    })
				},100);
				
			}
		}
	};
</script>

<style>
	
	.course-left-player {
		position: relative;
		background: #000;
		margin: 15px;
	}
	.course-left-explain {
		height: 100px;
		padding: 0px 15px;
	}
	.course-left-explain h5{
		margin: 0px;
		padding: 4px;
		color: #d4d4d4;
		font-size: 20px;
	}
	.line-topup{
		position: relative;
	}
	#player .payQrStyle {
		margin-top: 10vh !important;
		height: 320px;
		padding-top: 25px;
		width: 500px;
		position: relative;
		background-color: #161823;
	}
	#player .payQrStyle p {
		margin: 0px;
	}
	#player .el-dialog__header{
		padding: 0px;
	}
	.line-topup .el-dialog__header{
		padding: 0px;
	}
	#player .OrderTitle{
		font-size: 17px;
		padding: 25px 35px 10px 35px;
		color: #efefef;
	}
	#player .orderitem {
		text-align: left;
		padding-left: 35px;
		padding-top: 10px;
		color: #d9d9d9; 
	}
	#player .yuebuz{
		padding-left: 40px;
		font-size: 12px;
		height: 20px;
		line-height: 20px;
		color: #ff5c0b;
		padding-top: 10px;
	}
	#player{
		background-color: #fff;
	}
	#player .introduce{
		margin: 5px;
		font-size: 13px;
		padding: 0px 5px;
		color: #d4d4d4;
	}
	#player .studyers{
		height: 22px;
		line-height: 22px;
		margin: 0px;
		padding: 5px 10px;
		padding-bottom: 0px;;
		font-size: 12px;
		color: #666;
	}
	#player .studyers1{
		height:20px;
		line-height: 22px;
		margin: 0px;
		padding: 0px 6px;
		padding-bottom: 0px;;
		font-size: 12px;
		color: #9a9a9a;
		text-align: right;
	}
	#player .permis{
		float: right;
	}
	
	#player .shelvestime{
		margin: 0px;
		padding: 0px 10px;
		font-size: 12px;
		text-align: right;
		color: #73767A;
		
	}
	#player .price{
		height: 30px;
		line-height: 30px;
	}
	#player .amount{
		color: #b4b4b4;
	}
	#player .playlien{ 
		float: right;
		font-size: 20px;
		color: #35aeff;
		cursor: pointer;
		padding-top: 10px;
		margin-right: 3px;
		margin-left: 5px;
	}


	#player .el-collapse-item__header{
		height: 28px;
		background-color: #f1f1f1;
		padding: 0px 15px;
	}
	#player .el-collapse-item__content{
		padding-bottom: 0px;
	}
	

	.course-right-director {
		margin:5px 15px;
		margin-left: 0px;
		padding-right: 15px;
	}

	.course-right-director-msg {
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid #e5e5e5;
		padding: 3px;
		padding:0px 15px;
		padding-right: 0px;
		
		
	}
	.course-right-director-msg:hover{
		background-color: #e6f7e9;
	}

	.badge-item {
		padding: 2px 6px;
		font-size: 10px;
		border-radius: 3px;
		color: white;
		margin-left: 3px;
	}

	.course-right-recommend {
		background: white;
		margin-right: 20px;
		margin-top: 13px;
		background-color: #161823;
		box-sizing: border-box;
		padding: 0px;
	}

	.meta-info-unit {
		display: flex;
		padding: 10px 0;
		width: 100%;
		box-sizing: border-box;
		border-bottom: 1px solid #e5e5e5;
	}

	.meta-info-unit i {
		font-size: 25px;
		color: #f9a134;
		margin-right: 15px;
	}

	.meta-info-unit {
		color: #7d7e7f;
		font-size: 15px;
	}

	.course-right-teacher {
		width: 350px;
		box-sizing: border-box;
		padding: 30px 30px;
		background: white;
		border: 1px solid #e5e5e5;
		margin-top: 20px;
		border-radius: 10px;

	}

	.course-right-teacher-title {
		display: flex;
	}

	.course-left-player-img {
		width: 800px;
		height: 500px
	}
	.tis{
		width: 120px;
		position: absolute;
		padding: 3px;
		margin-top: -75px;
		margin-left: 10px;
		opacity: 0.9;

	}

</style>
