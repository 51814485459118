<template>
	<el-row id="CommunityStudents">
		<el-col :span="12" class="title family-our">
			<p style="margin-bottom: 0px;font-size: 24px;color: #e3eef4; ">社群学员认证</p>
			<p style="margin-top: 0px;color: #c7c7c7;">Community Student Certification</p>
			<el-col :span="18">
				<el-card style="background-color: #fffbfe;">
					<p>定期开放名额 <small>报名时间等待通知</small></p>
					<el-button type="danger" plain disabled>报名</el-button>
				</el-card>
			</el-col>
		</el-col>
		<el-col :span="10" style="padding:0px 25px;">
			<p class="family-our say">社群学员认证 介绍</p>
			<p class="family-black p">
				&nbsp;&nbsp;&nbsp;&nbsp;社群学员，由米高老师开设专属讲堂，与米高老师面对面进行授课，更加全面提供教学服务！
			</p>
			<p class="family-black p">
				对于我们的社群学员，我们会定期在线下开设讲堂，与米高老师面对面学习，在学习中发生的任何疑问都可以及时的提问与答复！
			</p>
			<p>权限：</p>
			<p class="m">1.开放所有线上课程，但不包括付费课程</p>
			<p class="m">2.开放所有线上课配套课件，但不包括付费课件</p>
			<p class="m">3.学习结束后，可以继续选择回来跟班在次学习。</p>
		</el-col>
	</el-row>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				
			}
		},
		mounted() {
		
		},

		methods: {
		
		}
	}
</script>

<style>
	#CommunityStudents{
		/* background-color:#1F1F1F; */
		/* background-image: linear-gradient(to top right,  #c7e3ef ,#ab0000); */
		height: calc(100vh - 72px);
		background-color:#161823;
		color: #e5e5e5;
	}
	#CommunityStudents .title{
		height: 150px;
		padding-left: 150px;
		padding-top: 130px;
		font-weight: 800;
		font-size: 20px;
	}
	#CommunityStudents .say {
		text-align: center;
		font-weight: bold;
		padding-bottom: 15px;
		font-size: 24px;
		margin-top: 70px;
		margin-left: 50px;
		margin-right: 50px;
		border-bottom: 1px solid #eee;
	}
	#CommunityStudents .p{
		font-size: 15px;
		margin: 0px;
	}
	#CommunityStudents .m{
		font-size: 15px;
		margin: 4px;
	}
</style>
