<template>
	<el-container>
		<el-header id="TITLE">
			<Title></Title>
		</el-header>
		<el-container>
			<el-aside id="TAB">
				<Tab></Tab>
			</el-aside>
			<el-container>
				<el-main id="BODY" :style="'height:'+(states.Height-72)+'px'">
					<Body></Body>
				</el-main>
			</el-container>
		</el-container>
	</el-container>
</template>

<script>
	import Title from './child/Title.vue' // 首页-头部
	import Tab from './child/Tab.vue' // 首页-左边标签页
	import Body from './child/Body.vue' // 首页-主体
	import {
		mapGetters
	} from 'vuex'
	export default {
		components: {
			Title: Title,
			Tab: Tab,
			Body: Body
		},
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				
			}
		},
		mounted() {
			
		},
		methods: {

		}
	}
</script>

<style>
	#TITLE{
		background-color: #161823;
		height: 72px!important;
		line-height: 72px!important;
	}
	#TAB{
		width: 160px!important;
		background-color: #161823;
		height: calc(100vh - 72px);
	}
	#TAB::-webkit-scrollbar {
		width: 0px;
	}
	#TAB::-webkit-scrollbar-thumb {
		border-radius: 7px;
		-webkit-box-shadow: inset 0 0 5px rgba(255,103,22, 0.5);
		background: rgba(255,103,22, 0.5);
	}
	
	#TAB::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 1.0);
		border-radius: 0;
		background: rgba(207, 207, 207, 0.2);
	} 
	#BODY{
		padding: 0px;
		/* background-color: #5dccff; */
		background-color: #161823;
		
		
	}
</style>
