<template>
  <div :style="'height: '+(states.Height-75)+'px;overflow: auto;padding:0px 10px;background-color: #fff;'" class="course_List" >
      <el-tabs v-model="states.CourseTab">
        <el-tab-pane label="课程发布" name="issue">
          <updata />
        </el-tab-pane>
		<el-tab-pane label="课程管理" name="published">
          <courselist />
        </el-tab-pane>
       <el-tab-pane label="评论审核" name="discuss">
          <!-- <comments /> -->
        </el-tab-pane>
       
      </el-tabs>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import courselist from './courselist/index'
import updata from './updata/index'
import comments from './comments'
export default {
	computed: {   
		...mapGetters(['states'])
	},
  components: {
    courselist: courselist,
    updata: updata,
    comments: comments
  },
  data() {
    return {

    }
  }
}
</script>

<style>
	.course_List::-webkit-scrollbar {
		width: 4px;
	}
	.course_List::-webkit-scrollbar-thumb {
		border-radius: 7px;
		-webkit-box-shadow: inset 0 0 5px rgba(255,103,22, 0.5);
		background: rgba(255,103,22, 0.5);
	}
	
	.course_List::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 1.0);
		border-radius: 0;
		background: rgba(207, 207, 207, 0.2);
	}
</style>
