<template>
	<comment id="Course" :avatar="states.userinfo.avatarurl" commentWidth="100%" @doSend="InfoSend"
		@doChidSend="itemSend" placeholder="精彩的评论.." :authorId="1" :commentList="commentList"
		:commentNum="score"></comment>
	<!-- :authorId="this.states.playerSource.course.course_id"  作者ID 可在评论区标注作者标签-->
</template>
<script>
	import comment from 'bright-comment'
	import {
		mapGetters
	} from 'vuex'
	export default {
		components: {
			comment
		},
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				score:0,
				timer: null,
				commentList: []
			}
		},
		mounted() {
			this.init()
			this.timer = setInterval(this.init, 20000);
		},
		methods: {
			init() {
				this.$store.dispatch('DiscussSay', {
					type: '2',
					course: this.states.playerSource.course.course_id
				}).then(res => {
					if (res.code == '200') {
						this.commentList = res.data[0]
						this.score = res.data[1]
						console.log("刷新")
					}
				})
			},
			InfoSend(e) {
				let uinfo = this.states.userinfo
				this.$store.dispatch('DiscussSay', {
					uid: uinfo.id,
					content: e,
					type: '0',
					course: this.states.playerSource.course.course_id
				}).then(res => {
					if (res.code == '200') {
						this.commentList = res.data[0]
						this.score = res.data[1]
					}
				})
			},
			itemSend(e, i, s) {
				let uinfo = this.states.userinfo
				this.$store.dispatch('DiscussSay', {
					uid: uinfo.id,
					uid1: i,
					comments1: s,
					content: e,
					type: '1',
					course: this.states.playerSource.course.course_id
				}).then(res => {
					if (res.code == '200') {
						this.commentList = res.data[0]
						this.score = res.data[1]
					}
				})
			}
		},
		beforeDestroy() {
		  clearTimeout(this.timer);
		},
	}
</script>

<style>
	#Course .hbl-comm {
		padding: 10px !important;
		padding-left: 20px !important;
	}
	#Course .comment-avatar {
		display: none!important;
	}
	#Course .comm{
		color:#fff;
	}
	#Course .hbl-child{
		padding: 5px;
		color:#fff;
	}
	#Course .icon {
		background:none;
		color:#1687f8;
		padding:0px;
	}
</style>

