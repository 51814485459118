<template>
	<el-row id="onlineStudents">
		<el-col :span="11" style="padding:0px 0px 0px 30px;color: #eeeeee;">
			<p class="family-our say">线上学员 介绍</p>
			<p class="family-black p">
				&nbsp;&nbsp;&nbsp;&nbsp;线上学员，作为一个轻产品推出，专门针对平时很忙的企业家们，提供教学服务！
			</p>
			<p class="family-black p">
				对于我们的线上学员，我们独立编制出了一套在线上的教学方案，从认知知识产权到规避风险，让学员从零基础到熟练，无缝学习！
			</p>
			<p style="color: #eee;">说明</p>
			<p class="m">1.开放所有线上课程，但不包括付费课程</p>
			<p class="m">2.开放所有线上课配套课件，但不包括付费课件</p>
			<p class="m">3.有机会得到社群体验课资格</p>
			<p class="m">4.在平台任意购买一门课程即可成为学员身份</p>
			<p class="m">5.当学时达到100学时时可授予‘商标行业证书培训考核合格证书’</p>
		</el-col>
		<el-col :span="13" class="title family-our">
			<img src="../../assets/zs.jpg" style="width: 400px;" alt="">
		<!-- 	<p style="margin-bottom: 0px;font-size: 24px;">线上学员认证</p>
			<p style="margin-top: 0px;">Online Student Certification</p>
			<p v-if="states.userinfo.role == 2">
				<el-button type="success">已认证</el-button>
				<p style="font-size: 17px;color: #FFF1F0;">认证时间:{{time((states.userinfo.Due-31536000)*1000)}}</p>
				<p style="font-size: 17px;color: #FCFFE6;">到期时间:{{time((states.userinfo.Due)*1000)}}</p>
			</p>
			<el-col :span="18"  v-if="states.userinfo.role == 1">
				<el-card style="background-color: #f3fff6;">
					<p>认证费：<del style="color: #333;">￥1880 元</del></p>
					<p>需实缴：<span style="color:#333">￥388 元</span></p>
					<p>期限： 一年</p>
					<el-button type="primary" plain @click="payshow">开始认证</el-button>
				</el-card>
			</el-col> -->
			
		</el-col>
		<el-dialog :visible.sync="pay" :before-close="payQrClose" custom-class="payQrStyle" :show-close="false">
			<el-row style="margin: 0px 20px;">
				<el-col :span="24">
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<p style="text-align: left; font-size: 20px;margin-top: 0px;font-weight: bold;">订单信息</p>
						<p style="text-align: left; color: #13CE66;">账号：{{states.userinfo.mobile}}</p>
						<p style="text-align: left; color: #F09233;">余额：￥{{states.userinfo.money}} 元</p>
						<p style="text-align: left; color: #CF9236;">待支付金额：￥388.00 元</p>
						<p style="text-align: left; color: #333;padding-right: 15px;">
							&nbsp;&nbsp;&nbsp;<b>注：</b>本次认证有效期限维持一年。</p>
						<p style="text-align: center;">
							<el-button type="primary" @click="off()" style="width: 80%;">关闭支付</el-button>
						</p>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="margin-bottom: 20px;text-align: center;">
						<el-col :span="24" style="text-align: left;padding-left: 25px;">
							<p style="margin-bottom: 0px;">您正在使用余额支付业务...</p>
							<p style="color: red;margin-top: 5px;" v-if="states.userinfo.money < 388">
								当前检测到您的余额不足以支付本笔订单,请先充值！
								<el-button type="primary" size="mini" @click="states.linepay = true">在线充值</el-button>
							</p>
						</el-col>
						<el-col :span="24" v-if="states.userinfo.money > 388">
							<i class="el-icon-success" style="font-size: 145px;color: #008000;"></i>
						</el-col>
						<p v-if="states.userinfo.money > 388">
							<el-button type="success" style="width:80%;" @click="PayIng(states.userinfo)" :loading="lock">{{lock?'正在支付':'支付'}}</el-button>
						</p>
					</el-col>
				</el-col>
			</el-row>
			<el-dialog :visible.sync="states.linepay" append-to-body custom-class="line-topup" :before-close="fun" :show-close="false">
				<topup></topup>
			</el-dialog>
		</el-dialog>
	</el-row>
</template>

<script>
	import {timestampToDate} from '@/utils/fun'
	import topup from '../topup' // 充值
	import {mapGetters} from 'vuex'
	export default {
		components: {
			topup: topup
		},
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				pay: false,
				payQr: '',
				orderInfo: {},
				lock: false,
			}
		},
		mounted() {
		
		},
		methods: {
			time(e){
				return timestampToDate(e)
			},
			fun(){
				
			},
			// 支付
			PayIng(event){
				// 扣除余额 升级账号
				this.$store.dispatch('PayMember',{uid:event.id,key:1,price:388}).then(res=>{
					this.states.userinfo = res.data
					if(res.role){
						this.states.userinfo.role = 2
					}
					localStorage.setItem('userinfo', JSON.stringify(res.data));
					this.$store.dispatch('OTHERINFO')   // 用户额外信息
					this.pay = false
				}) 
			},
			off() {
				this.pay = false
				this.payQr = ''
			},
			payshow(){
				this.pay = true
				this.lock = false
			},
			// 付款二维码
			payQrClose() {},
			itrue(e, v) {
				if (e) {
					return v ? false : true
				} else {
					return false
				}
			},
		}
	}
</script>

<style>
	
	#onlineStudents{
		height: calc(100vh - 72px);
		background-color:#161823;
		/* background-image: linear-gradient(to top right, #e3dad9 , #27b537); */
		color: #fff;
	}
	#onlineStudents .title{
		height: 150px;
		padding-left: 150px;
		padding-top: 30px;
		font-weight: 800;
		font-size: 20px;
	}
	#onlineStudents .say {
		text-align: center;
		font-weight: bold;
		padding-bottom: 15px;
		font-size: 24px;
		margin-top: 70px;
		margin-left: 50px;
		margin-right: 50px;
		border-bottom: 1px solid #eee;
	}
	#onlineStudents .p{
		font-size: 15px;
		margin: 0px;
	}
	#onlineStudents .m{
		font-size: 15px;
		margin: 4px;
		padding-left: 10px;
		color: #b4b4b4;
	}
	#onlineStudents .payQrStyle {
		margin-top: 10vh !important;
		min-height: 300px;
	}
	.line-topup {
		height: 590px;
		background-color: #FFFFFF;
		margin-top: 5vh !important;
	}
	@media (max-width: 690px) {
		#onlineStudents .payQrStyle {
			width: 345px;
		}
	}
</style>
