<template>
	<el-col :span="24" style="height: 72px;line-height: 72px;cursor: pointer;position: relative;">
		<span id="logo"></span>
		<div id="searc">
			<el-autocomplete popper-class="my-autocomplete" v-model="states.query" :fetch-suggestions="querySearch" @select="handleSelect" :placeholder="hop" class="searc-h" id="IMPU">
				<template slot-scope="{item}">
					<div class="name">{{ item.name }}
						<el-tag class="tag_sele" :type="item.key == 'ds_blog'?'primary':'success'">{{item.key == 'ds_blog'?'Blog':'课程'}}</el-tag>
					</div>
				</template>
			</el-autocomplete>
			<el-button type="text" icon="el-icon-search" style="color: #fff;margin-left: 15px;font-weight: 500;font-size: 17px;font-weight: 500;" @click="search(states.query)">搜索</el-button>
		</div>
		<el-button type="danger" icon="el-icon-user-solid" plain class="logion" size="small"  @click="states.LoginShow = true" v-if="!states.islogin">登录</el-button>
		<el-dialog :visible.sync="states.LoginShow" :before-close="LoginClose" custom-class="Login" :show-close="false" close-on-press-escape>
			<Login v-if="states.LoginShow"></Login>
		</el-dialog>
		<span v-if="states.islogin" @click="showfun">
			<el-avatar :src="states.userinfo.avatarurl" style="width: 45px;height: 45px;position: absolute;right: 55px; top: 15px;z-index: 999;border: 2px solid #000000;"></el-avatar>
		</span>
		<transition name="el-zoom-in-top">
		    <div class="userBox" v-show="bool">
		    	<el-col :span="24" style="position: relative;border-bottom: 1px solid #a1a1a1;height: 80px;">
		    		<div class="username">
		    			{{states.userinfo.ds_name}}
		    			<span v-if="states.userinfo.role == 1" style="font-size: 12px;"> 未认证</span>
		    			<span v-if="states.userinfo.role == 2" style="color: #1890FF;font-weight: bold;font-size: 12px;"> 
		    				<el-tooltip class="item" effect="dark" :content="'到期时间:'+timestampToDate(states.userinfo.Due*1000)" placement="right-start">
		    					  <el-button type="text" size="mini" style="padding: 0px;">&nbsp;线上学员</el-button>
		    				</el-tooltip>
		    			</span>
		    			<span v-if="states.userinfo.role == 3" style="color: #d42828;font-weight: bold;font-size: 12px;"> 社群学员</span>
		    		</div>
		    		<div class="userreg"><b>注册:</b> {{$timestampToDate(states.userinfo.ctime*1000)}}</div>
		    	</el-col>
		    	<el-col :span="24" style="padding-left: 30px;">
		    		<p style="font-size: 12px;margin: 5px 0px;height: 20px;"><b>手机号码:</b> {{states.userinfo.mobile}} </p>
		    		<p style="font-size: 12px;margin: 5px 0px;height: 20px;"><b>邀请编码:</b> {{states.userinfo.invitecode}} </p>
		    		<p style="font-size: 12px;margin: 5px 0px;height: 20px;"><b>累计学时:</b> <span style="color: #a68303;">{{(states.userinfo.learning/60/60).toFixed(2)}}</span> 学时 </p>
		    		<p style="font-size: 12px;margin: 5px 0px;height: 20px;"><b>平台余额:</b> <span style="color: #00a600;">{{states.userinfo.money}}</span> 元 </p>
		    	</el-col>
		    	<el-col :span="24" style="padding-left: 30px;margin-top: 10px;">
		    		<el-button type="text" size="small" @click="switchover(8)">余额充值</el-button>
		    		<el-button type="text" size="small" @click="switchover(7)">修改资料</el-button>
		    		<el-button type="text" size="small" @click="switchover(9)">余额细明</el-button>
		    		<el-button type="text" size="small" @click="switchover(10)" style="color: #179A16;">线上学员</el-button>
		    		<el-button type="text" size="small" @click="switchover(11)" style="color: orangered;">社群学员</el-button>
		    		<el-button type="text" size="small" @click="OutLogin">退出登录</el-button>
		    	</el-col>
		    </div>
		</transition>
	</el-col>
</template>

<script>
	import {OCURSECONTENT} from '@/utils/fun'
	import Login from '@/components/Login.vue' // 首页-头部
	import {
		mapGetters
	} from 'vuex'
	export default {
		components: {
			Login: Login,
		},
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				hop:'',
				restaurants: [],
				state: '',
				timeout: null,
				bool:false,
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			showfun(){
				this.bool = !this.bool
			},
			switchover(e){
				this.states.selected = 0
				setTimeout(() =>{
				    this.states.selected = e
					if(this.states.selected == 2){
						this.states.playSoutce = {}
						
					}
				},200);
			},
			// 退出登录
			OutLogin(){
				localStorage.clear();
				this.states.LoginShow = false
				this.states.selected = 1
				this.states.islogin = false
				this.bool = false
				this.states.userinfo = {}
				this.$notify({ title: '提示', message: '已退出', type: 'warning' });
			},
			init(){
				this.$store.dispatch('AllSearch',{keyword:'',key:1}).then(result=>{
					let n = Math.floor(Math.random() * result.data.length + 1)-1
					this.hop = result.data[n].name
				})
			},
			search(event){
				if(event){
					this.states.query = event
					document.getElementById("IMPU").focus()
				}else{
					this.states.query = this.hop
					document.getElementById("IMPU").focus()
				}
			},
			// 打开
			handleSelect(item) {
				if(item.key == 'ds_blog'){
					// 打开Blog
					this.states.selected = 2
					this.states.searchB = item
				}else if(item.key == 'ds_course'){
					// 打开课程
					this.states.selected = 0
					setTimeout(() =>{
						if(JSON.stringify(this.states.userinfo) != '{}'){
							this.states.query = ''
							this.hop = item.name
							this.states.ispermis = false
							this.$store.dispatch('courseInfo',{course_id:item.id,uid:this.states.userinfo.id}).then(res=>{
								if(res.code == 200){
									this.states.playerSource = {course:res.course,chapter:OCURSECONTENT(res.data)}
									this.states.playerduring = OCURSECONTENT(res.data).chapter[0][0]
									this.states.ispermis = res.ispermis
									this.states.selected = 999
								}else{
									this.$notify({ title: '温馨提醒',message: '您还没获取学习本门课程的资格呢！',type: 'warning'});
								}
							})
							
						}else{
							this.states.selected = 1
							this.$notify({ title: '温馨提醒',message: '请您先登录！',type: 'warning'});
						}
					},200);
				}
			},
			querySearch(queryString, cb) {
				if(this.states.query){
					this.$store.dispatch('AllSearch',{keyword:'',key:1}).then(result=>{
						cb(result.data.filter(data => !this.states.query || data.name.toLowerCase().includes(this.states.query.toLowerCase())))
					})
				}else{
					this.$store.dispatch('AllSearch',{keyword:'',key:1}).then(result=>{
						let n = Math.floor(Math.random() * result.data.length + 1)-1
						this.hop = result.data[n].name
						cb(result.data.filter(data => !queryString || data.name.toLowerCase().includes(queryString.toLowerCase())))
					})
				}
			},
			LoginClose() {}
		}
	}
</script>

<style>
	.tag_sele{
		float: right;
		height: 20px;
		width: 40px;
		line-height: 10px;
		padding: 3px 5px;
		margin-top: 7px;
	}
	/* .el-popper .popper__arrow{
		display: none;
	} */
	#btn {
		width: 34px;
		height: 34px;
		line-height: 34px;
		margin-top: 19px;
		display: inline-block;
		text-align: center;
		float: left;
		margin-right: 25px;
		border-radius: 55%;
	}

	#btn:hover {
		background-color: #f4f4f4;
	}

	#logo {
		width: 146px;
		height: 46px;
		display: inline-block;
		background: url('https://dsvi.com/logos2.png') no-repeat;
		background-size: 146px 44px;
		margin-top: 13px;
	}

	#searc {
		width: 500px;
		line-height: 46px;
		height: 49px;
		background-color: #FF6716;
		border-radius: 25px;
		display: inline-block;
		position: absolute;
		top: 13px;
		padding-left: 2px;
		left:180px;
	}

	#searc .el-input__inner {
		border-radius: 25px;
	}
	.searc-h {
		padding-left: 3px;
		width: 400px;
	}
	#searc .el-input.is-active .el-input__inner,
	.el-input__inner:focus {
		/* border-color: #FD503A; */
		border-color: #fd6f52;
	}
	.logion {
		position: absolute;
		right: 160px;
		margin-top: 20px;
		background-color: #fff !important;
		color: #FD503A !important;
	}
	.logion:hover {
		color: #FD503A !important;
	}
	.logion:active {
		color: #FD503A !important;
	}

	.logion:visited {
		color: #FD503A !important;
	}

	.Login {
		width: 400px;
		height: 320px;
	}

	#TITLE .el-dialog__header,
	.el-dialog__body,
	.el-dialog__footer {
		padding: 0px;
	}

	#TITLE .el-badge__content.is-fixed {
		top: 10px;
	}
	.userBox{
		width: 450px;
		height: 270px;
		background-color: #fff;
		position: absolute;
		right: 10px;
		z-index: 99;
		top: 60px;
		color: #fff;
		background-color: #2d3148;
		box-shadow: 0px 2px 15px rgba(57, 57, 57, 0.5);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.username{
		position: absolute;
		left: 30px;
	}
	.userreg{
		position: absolute;
		left: 30px;
		top: 20px;
		font-size: 12px;
		color: #e3e3e3;
	}
	
</style>
