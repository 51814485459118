<template>
		<el-row id="play">
			<div class='course-vue-play'>
				<video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" v-if="states.PlayerShow"
					:options="playerOptions" 
					@statechanged="playerStateChanged($event)" 
					@play="onPlayerPlay($event)"
					@ready="playerReadied"
					@pause="onPlayerPause($event)"
					></video-player>
			</div>
		</el-row>
	</el-col>
	</div>
</template>
<script>
	import {timestampToDate} from '@/utils/fun'
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() { 
			return {
				show:true,
				playerOptions: {
					playbackRates: [0.5,0.7, 1.0, 1.5, 2.0], // 播放速度
					autoplay: false, 					 	 // 是否一开始就播放
					muted: false, 						 	 // 是否一开始就静音
					loop: false, 						 	 // 循环播放
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "", // 视频格式
						src: ""   // 视频地址
					}],
					poster: "",   // 封面地址
					// width: document.documentElement.clientWidth, //播放器宽度
					notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true,
						durationDisplay: true,
						remainingTimeDisplay: false,
						fullscreenToggle: true, //全屏按钮，
					}

				},
				timeer: 0
			}
		},
		mounted() {
			this.playerOptions.sources[0].src = this.$ip()+this.states.playSoutce.respath
			this.playerOptions.poster = this.states.playSoutce.posters
			

		},
		methods: {
			// 准备播放
			playerReadied(player) {
				let t = localStorage.getItem('Blog'+this.states.playSoutce.blogID)
				if(t && t != 'undefined'){
					player.currentTime(t-1)
				}
			},
			//监听暂停
			onPlayerPause (player) {
				
			},
			// 监听播放状态改变 播放状态   1s ===5
			playerStateChanged($event) {
				this.timeer ++
				if(this.timeer===24){
				   localStorage.setItem('Blog'+this.states.playSoutce.blogID,$event.timeupdate);
				   this.timeer=0
				}
			},
			onPlayerPlay($event){
				let t = localStorage.getItem('Blog'+this.states.playSoutce.blogID)
				this.states.recordPlayBlog = t
				// 新增播放量
				if(this.states.islogin){
					console.log("++")
					this.$store.dispatch('AmountPlay',{blogID:this.states.playSoutce.blogID,uid:this.states.userinfo.id})
				}
				setTimeout(() =>{
					this.states.recordPlayBlog = null
				},3000);
			}
		}
	}
</script>
<style>

</style>
