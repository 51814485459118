<template>
	<el-row>
		<el-col :span="24" v-if="states.allshowcorser">
			<el-table :data="states.allcourses.slice((Page-1)*pagesize,Page*pagesize)" size="mini" :height="states.Height-161">
			    <el-table-column prop="coursename" label="课程名称" width="160"></el-table-column>
				<el-table-column prop="introduce" label="课程介绍" show-overflow-tooltip></el-table-column>
				<el-table-column prop="category" label="课程分类" align="center" width="120">
					<template slot-scope="scope">
						{{cla(scope.row.category)}}
					</template>
				</el-table-column>
				<el-table-column prop="category" label="课程权限" align="center" width="100">
					<template slot-scope="scope">
						<el-tag size="mini" type="primary" v-if="scope.row.permis == 0" title="免费公开课">公开课程</el-tag>
						<el-tag size="mini" type="success" v-if="scope.row.permis == 1" title="线上课程">线上课程</el-tag>
						<el-tag size="mini" type="danger" v-if="scope.row.permis == 2" title="社群学员专享">社群课程</el-tag>
						<el-tag size="mini" type="warning" v-if="scope.row.permis == 3" title="付费课程">付费课程</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="teacher" label="课程讲师" align="center" width="80"></el-table-column>		
				<el-table-column label="创建时间" align="center" width="125">
					<template slot-scope="scope">
						{{ Time(scope.row.shelvestime*1000) }}
					</template>
				</el-table-column>
				<el-table-column label="课程封面" align="center" width="100">
					<template slot-scope="scope">
						<el-image style="width: 75px; height: 50px" :src="scope.row.posters" :preview-src-list="[scope.row.posters]"></el-image>
					</template>
				</el-table-column>
				<el-table-column label="课程海报" align="center" width="100">
					<template slot-scope="scope">
						<el-image style="width: 75px; height: 50px" :src="scope.row.thumbnail" :preview-src-list="[scope.row.thumbnail]"></el-image>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="120" align="center">
				    <template slot-scope="scope">
						<p style="margin: 0;padding: 0;">
							<el-button type="primary" size="mini" @click="courseeditor(scope.row)" class="btn" >课程编辑</el-button>
						</p>
						<p style="margin: 0;padding: 0;">
							<el-button type="primary" size="mini" @click="toview(scope.row)" class="btn">章节编辑</el-button>
						</p>
						<p style="margin: 0;padding: 0;">
							<el-button :type="scope.row.isputaway == 0?'success':'danger'" size="mini" @click="upDown(scope.row)"class="btn">课程{{scope.row.isputaway == 0?'上':'下'}}架</el-button>
						</p>
				    </template>
				</el-table-column>
			</el-table>
			<el-col :span="24" style="text-align: center;">
				<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="Page" :page-sizes="[5,10,20,50]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="states.allcourses.length"></el-pagination>
			</el-col>
		</el-col>
		<el-col :span="24" v-if="!states.allshowcorser" style="padding:5px 15px;">
			<el-col :span="24">
				<el-button type="primary" size="mini" @click="addItem">添加章节</el-button>
				<el-button type="info" size="mini" @click="states.allshowcorser = true" style="float: right;">返回</el-button>
			</el-col>
			<el-table :data="states.allcourdernei" size="mini">
				<el-table-column prop="chapter" label="章" width="70">
					<template slot-scope="scope">第{{scope.row.chapter}}章</template>
				</el-table-column>
				<el-table-column prop="section" label="节" width="70">
					<template slot-scope="scope">第{{scope.row.section}}节</template>
				</el-table-column>
				<el-table-column label="封面" width="120" align="center">
					<template slot-scope="scope">
						<el-image style="width: 90px; height: 50px;" :src="scope.row.cover" :preview-src-list="[scope.row.cover]"></el-image>
					</template>
				</el-table-column>
				<el-table-column prop="abstract" label="标题"></el-table-column>
				<el-table-column prop="instructions" label="简介"></el-table-column>
				<el-table-column prop="category" label="权限" align="center" width="130">
					<template slot-scope="scope">
						<el-tag size="mini" type="primary" v-if="scope.row.permis == 0" title="免费公开课">公开课程</el-tag>
						<el-tag size="mini" type="success" v-if="scope.row.permis == 1" title="线上课程">线上课程</el-tag>
						<el-tag size="mini" type="danger" v-if="scope.row.permis == 2" title="社群学员专享">社群课程</el-tag>
						<el-tag size="mini" type="warning" v-if="scope.row.permis == 3" title="付费课程">付费课程</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="timelength" label="视频时长" width="100"></el-table-column>
				<el-table-column label="操作" width="120" align="center">
				    <template slot-scope="scope">
						<p style="margin: 0;padding: 0;">
							<el-button type="primary" @click="changecourseitem(scope.row)" size="mini">修改信息</el-button>
						</p>
				    </template>
				</el-table-column>
			</el-table>
		</el-col>
		
		
		
		
		<!-- 弹框修改课程信息 -->
		<el-dialog title="修改课程信息" :visible.sync="dialogVisible" custom-class="editorCourse">
			<!-- 表单内容 -->
			<el-form  label-width="80px" size="mini">
				<el-form-item label="课程名称">
					<el-input v-model="formCourse.coursename"></el-input>
				</el-form-item>
				<el-form-item label="课程分类">
					<el-select v-model="formCourse.category" placeholder="请选择">
					    <el-option v-for="item in states.CategoryData" :key="item.classID" :label="item.className" :value="String(item.classID)"> </el-option>
					  </el-select>
					<!-- <el-input v-model="formCourse.category"></el-input> -->
				</el-form-item>
				<el-form-item label="课程讲师">
					<el-input v-model="formCourse.teacher"></el-input>
				</el-form-item>  
				<el-form-item label="课程说明">
					<el-input v-model="formCourse.introduce" type="textarea" :rows="2"></el-input>
				</el-form-item>
				<el-form-item label="课程权限">
					<el-radio v-model="formCourse.permis" label="0">公开课程</el-radio>
					<el-radio v-model="formCourse.permis" label="1">线上课程</el-radio>
					<el-radio v-model="formCourse.permis" label="2">社群课程</el-radio>
					<el-radio v-model="formCourse.permis" label="3">付费课程</el-radio>
				</el-form-item>
				<el-form-item label="课程售价" v-if="formCourse.permis == '3'">
					<el-input v-model="formCourse.price" placeholder="请输入内容"></el-input>
				</el-form-item>
				<el-form-item label="封面/海报">
					<el-col :span="12">
						<span>封面标准比例为:1.61:1</span>
						  <div class="uploadImg" @click="upload(0)" v-loading="loading1">点击添加图片
						    <img :src="formCourse.posters" class="uploadImg-img" v-if="formCourse.posters">
						  </div>
						</span>
						<input v-show="false" ref="input1" type="file" @change="handlechange1">
					</el-col>
					<el-col :span="12">
						<span>海报标准比例为:1.61:1</span>
						<div class="uploadImg" @click="upload(1)" v-loading="loading2">点击添加图片
						  <img class="uploadImg-img" :src="formCourse.thumbnail" v-if="formCourse.thumbnail">
						</div>
						<input v-show="false" ref="input2" type="file" @change="handlechange2">
					</el-col>
				</el-form-item>	
				<el-form-item>
					<el-button type="primary" @click="confirmCourse" size="mini">提交</el-button>
					<el-button @click="dialogVisible = false" size="mini">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		
		

			<!-- 弹框修改章节信息 -->
			<el-dialog title="修改课程信息" :visible.sync="dialogVisible1" custom-class="editorCourseItem">
				<!-- 表单内容 -->
				<el-form  label-width="80px" size="mini">
					<el-form-item label="课程标题">
						<el-input v-model="formCourseItem.abstract"></el-input>
					</el-form-item>
					<el-form-item label="课程简介">
						<el-input v-model="formCourseItem.instructions"></el-input>
					</el-form-item>
					<el-form-item label="视频封面">
						<el-col :span="12" id="UFM">
							<el-col :span="24" v-loading="loading">
								<el-upload class="avatar-uploader" :before-upload="UpRules" action="#" :show-file-list="false" :http-request="upload" :data="{data:formCourseItem}">
									<img v-if="formCourseItem.cover" :src="formCourseItem.cover" class="avatar" style="width: 130px; height: 80px;">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-col>
						</el-col>
						<el-col :span="12" v-if="formCourseItem.cover">
							<el-image style="width: 130px; height: 80px;border: 1px solid #EEEEEE;" :src="formCourseItem.cover" :preview-src-list="[formCourseItem.cover]"></el-image>
						</el-col>
					</el-form-item>
					<el-form-item label="视频时间">
						<span>{{formCourseItem.timelength}}</span>
					</el-form-item>
					<el-form-item label="视频源">
						<video :src="formCourseItem.respath" controls="controls" width="380px"></video>
						<el-col :span="24">
							<span style="position: absolute;right: 10px;margin-top: 9px;">{{(jd * 100).toFixed(2)}} %</span>
							<p style="width: 90%;">
								<el-progress :percentage="Number(jd * 100)" :status="jd == 1?'success':'warning'"></el-progress>
							</p>
						</el-col>
					</el-form-item>
					<el-form-item >
						<el-button type='primary' @click='changeVideoUrl'>点击更换视频</el-button>
						<input v-show="false" ref="input3" type="file" @change="preview($event,formCourseItem)">
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<small style="color: #EE0202;padding-right: 15px;" v-if="formCourseItem.file">视频已置换，提交会自动上传新的视频，如需取消视频上传，请按取消按钮即可！</small>
					<el-button @click="dialogVisible1 = false">取 消</el-button>
					<el-button type="primary" @click="uploadcourseitem(formCourseItem)">提 交</el-button>
				</span>
			</el-dialog>
			<!-- 添加章节信息 -->
			<el-dialog title="修改课程信息" :visible.sync="dialogVisible2"  custom-class="AddCourseItem">
				<el-form  label-width="80px" size="mini">
					<el-form-item label="课程标题">
						<el-input v-model="addCourseItem.abstract"></el-input>
					</el-form-item>
					<el-form-item label="课程简介">
						<el-input v-model="addCourseItem.instructions"></el-input>
					</el-form-item>
					<el-form-item label="章">
						
						<el-select v-model="addCourseItem.chapter"  placeholder="请选择">
							<el-option :label="item" :value="item" v-for="(item,index) in arrlength" :key="index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="节">
						<el-input v-model="addCourseItem.section" :disabled="!isdisabled"></el-input>
					</el-form-item>
					<el-form-item label="视频封面">
						<el-col :span="12" id="UFM">
							<el-col :span="24" v-loading="loading">
								<el-upload class="avatar-uploader" :before-upload="UpRules" action="#" :show-file-list="false" :http-request="upload" :data="{data:addCourseItem}">
									<img v-if="addCourseItem.cover" :src="addCourseItem.cover" class="avatar" style="width: 130px; height: 80px;">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-col>
						</el-col>
						<el-col :span="12" v-if="addCourseItem.cover">
							<el-image style="width: 130px; height: 80px;border: 1px solid #EEEEEE;" :src="addCourseItem.cover" :preview-src-list="[addCourseItem.cover]"></el-image>
						</el-col>
					</el-form-item>
					<el-form-item label="视频时间">
						{{addCourseItem.timelength}}
					</el-form-item>
					<el-form-item label="视频源">
						<video :src="addCourseItem.respath" controls="controls" width="380px" v-if="addCourseItem.respath"></video>
						<el-col :span="24">
							<span style="position: absolute;right: 10px;margin-top: 9px;">{{(jd * 100).toFixed(2)}} %</span>
							<p style="width: 90%;">
								<el-progress :percentage="Number(jd * 100)" :status="jd == 1?'success':'warning'"></el-progress>
							</p>
						</el-col>
					</el-form-item>
					<el-form-item >
						<el-button type="primary" @click="addVideo">上传视频</el-button>
						<input v-show="false" ref="input4" type="file" @change="preview($event,addCourseItem)">
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible2 = false">取 消</el-button>
					<el-button type="primary" @click="addcourseitem(addCourseItem)">提 交</el-button>
				</span>
			</el-dialog>
	</el-row>
</template>

<script>
import {timestampToTimes} from '@/utils/fun'
import {PostEditorFile} from '@/utils/request'
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				dialogVisible:false,			 // 课程信息弹框
				dialogVisible1:false,			 // 课程章节信息弹框
				dialogVisible2:false,			 // 添加章节信息
				formCourse: {}, 				 // 课程信息
				formCourseItem:{},			 	 // 章节信息
				c_id:0, 						 // 保存课程id
				p_id:0,							 // 保存课程权限
				addCourseItem:{},		 		 // 添加章节
				arrlength:[], 					 // 章的长度 
				slength:null, 					 // 节的长度
				isdisabled:false, 				 // 禁用选择小节按钮
				isVideoShow:false, 				 // 添加组件视频显示
				oss:{
				  region: 'oss-cn-shenzhen',
				  success_action_status: '200', 	
				  accessKeyId: 'LTAI4FsrLnoktYR84ahTbigL',  									
				  accessKeySecret: 'PqtmAi5Vh0fP9Z9Q9xGOfJ9bSEE73E',								
				  bucket: 'dscourse',
				},
				Page: 1,
				pagesize: 5,
				loading:false,
				loading1:false,
				loading2:false,
				client:null,
				jd:0,							// 进度条
			}
		},
		mounted() {
			this.client = new OSS(this.oss)
			this.$store.dispatch('GetCourses').then(res=>{
				this.states.allcourses = res.data
				this.states.allshowcorser = true
			})
		},
		watch:{
			"addCourseItem.chapter":function(newVal){
				if(newVal==this.arrlength[this.arrlength.length-1]){
					this.addCourseItem.section=1
				}else{
					this.addCourseItem.section = this.slength
				}
			}
		},
		methods: {
			cla(e){
				let name = ''
				this.states.CategoryData.forEach(item=>{
					if(item.classID == e){
						name = item.className
					}
				})
				return name
			},
			SizeChange(val) {
				this.pagesize = val
			},
			CurrentChange(val) {
				this.Page = val
			},
			Time(e){
				return timestampToTimes(e)
			},
			//课程章节编辑
			courseeditor(e) {
				this.dialogVisible = true
				e.permis = String(e.permis)
				this.formCourse=e
			},
			// 激活上传
			upload(e){
				if(e==0){
					this.$refs.input1.click()
				}else{
					this.$refs.input2.click()
				}
			},
			handlechange1(e) {
			  this.loading1 = true
			  let file = e.target.files[0]
			  const filesuffix = file.name.split('.')[1]
			  const pathname = `cover_${new Date().getTime()}.${filesuffix}`
			  PostEditorFile(file,pathname).then(res=>{
				  this.loading1 = false
				  this.formCourse.posters = res.data.data
			  })
			},
			handlechange2(e){
			  this.loading2 = true	
			  let file = e.target.files[0]
			  const filesuffix = file.name.split('.')[1]
			  const pathname = `courseimg_${new Date().getTime()}.${filesuffix}`
			  PostEditorFile(file,pathname).then(res=>{
				    this.loading2 = false
			  		this.formCourse.thumbnail = res.data.data
			  }) 
			},
			// 更新课程信息
			confirmCourse(){
				this.$store.dispatch('UpCourses',this.formCourse).then(res=>{
					this.formCourse = {}
					this.dialogVisible = false
				})
			},
			// 课程上下架
			upDown(e) {
				e.isputaway = !e.isputaway
				this.$store.dispatch('UpCourses',e).then(res=>{})
			},
			// 章节编辑
			toview(e){
				this.$store.dispatch('GetSection',{course_id:e.course_id}).then(res=>{
					this.c_id = e.course_id
					this.p_id = e.permis
					this.states.allcourdernei = res.data
					this.states.allshowcorser = !this.states.allshowcorser
				})	
			},
			// 修改课程章节信息
			changecourseitem(e){
				this.jd = 0
				this.dialogVisible1 = true
				e.file = null
				this.formCourseItem = e
			},
			//上传文件过滤限制
			UpRules(file) {
				const isLt2M = file.size / 1020 / 1020 < 1.9;
				if (!isLt2M) {
					this.$message({message: '封面不得超过2MB',type: 'warning'})
				}
				return isLt2M;
			},
			upload(event){
				this.loading = true
				let name = 'cover_' + new Date().getTime()+(event.file.type.split("/")[1] == 'jepg'?'.jpg':'.png')
				PostEditorFile(event.file,name).then(response=>{
						event.data.data.cover = response.data.data
						this.loading = false
				})
				setTimeout(() =>{
				    this.loading = false
				},4000);
			},
			// 更换视频
			changeVideoUrl(){
				this.$refs.input3.click()
			},
			// 选择视频
			preview(e,d) {
			  if (e.target.files[0]) {
			    let file = e.target.files[0]
				let url = this.getObjectURL(file)
				let timer = 0
			    // 获取视频大小
				d.respath = ''
			    this.getvideotime(file).then(res=>{
					d.respath = url
					d.timelength = res
					d.file = file
				})
			  }
			},
			getObjectURL(file) {
			  let url = null
			  if (window.createObjectURL !== undefined) {
			    url = window.createObjectURL(file)
			  } else if (window.webkitURL !== undefined) {
			    url = window.webkitURL.createObjectURL(file)
			  } else if (window.URL !== undefined) {
			    url = window.URL.createObjectURL(file)
			  }
			  return url
			},
			// 获取每个视频的播放时长
			getvideotime(file) {
				return new Promise((resolve, reject) => {
					const url = URL.createObjectURL(file)
					const videos = document.getElementById('audio_id')
					videos.src = url
					videos.oncanplay = function() {
					  let timer = 0
					  timer = Number(videos.duration)
					  timer = timer.toFixed(0)
					  const minute = Math.floor(timer / 60)
					  const seconds = Math.floor(timer % 60)
					  timer = `${minute}分${seconds}秒`
					  resolve(timer)
					}
				})
			},
			// 上传视频加修改信息
			uploadcourseitem(e) {
				if(e.file){
					let _this = this
					this.$message.success("开始上传视频")
					let file = e.file
					let filesuffix = file.name.split('.')[file.name.split('.').length-1]  // 后缀名
					let pathname = `video/${e.course_id}_${e.chapter}_${e.section}_${new Date().getTime()}.${filesuffix}`
					this.client.multipartUpload(pathname, file, {
						progress(p, checkpoint) {
							_this.jd = p
							// 请求写入数据库
							if (p == 1) {
								_this.$message.success('视频上传成功')
								setTimeout(() => {
									// 加入删除 源视频 在赋值
									let obj = {
										trademark_id:e.trademark_id,
										abstract:e.abstract,
										respath:'https://dscourse.oss-cn-shenzhen.aliyuncs.com/' + pathname,
										instructions:e.instructions,
										cover:e.cover,
										timelength:e.timelength
									}
									_this.$store.dispatch('UpSection',obj).then(res=>{
										_this.dialogVisible1 = false
										_this.states.allshowcorser = true
									})
								}, 300)
							}
						},
					}).catch(e=>{
						_this.$message.error('图片上传失败，请重新操作')
					})	
				}else{
					let obj = {
						trademark_id:e.trademark_id,
						abstract:e.abstract,
						respath:e.respath,
						instructions:e.instructions,
						cover:e.cover,
						timelength:e.timelength
					}
					this.$store.dispatch('UpSection',obj).then(res=>{
						this.dialogVisible1 = false
					})	
				}
			},
			// 添加小节
			addItem(){
				// 获取本课程章节信息,处理信息
				let arr=this.handleZj()
				// 初始化列表
				this.addCourseItem={
					course_id:this.c_id,
					file:null,
					abstract:'',
					instructions:'',
					chapter:arr.chapter[arr.chapter.length-1],
					section:1,
					respath:'',
					cover:'',
					timelength:'',
				}
				this.slength = arr.section+1
		
				this.arrlength=arr.chapter
				this.dialogVisible2=true
			},
			// 处理章节
			handleZj(){
				let section = []
				let chapter = []
				this.states.allcourdernei.forEach(item=>{
					section.push(item.section)
					chapter.push(item.chapter)
				})
				chapter = chapter.sort()
				chapter = Array.from(new Set(chapter))
				if(chapter.length != 0){
					chapter.push((Number(chapter[chapter.length-1])+1))
				}else{
					chapter = ['1']
				}
				return {chapter:chapter,section:section.length}
			},
			// 视频上传
			addVideo(){
				this.$refs.input4.click()
			},
			addcourseitem(e){
				if(e.file){
					let _this = this
					this.$message.success("开始上传视频")
					let file = e.file
					let filesuffix = file.name.split('.')[file.name.split('.').length-1]  // 后缀名
					let pathname = `video/${e.course_id}_${e.chapter}_${e.section}_${new Date().getTime()}.${filesuffix}`
					this.client.multipartUpload(pathname, file, {
						progress(p, checkpoint) {
							_this.jd = p
							// 请求写入数据库
							if (p == 1) {
								_this.$message.success('视频上传成功')
								setTimeout(() => {
									// 加入删除 源视频 在赋值
									let obj = {
										course_id:e.course_id,
										abstract:e.abstract,
										respath:'https://dscourse.oss-cn-shenzhen.aliyuncs.com/' + pathname,
										instructions:e.instructions,
										cover:e.cover,
										timelength:e.timelength,
										chapter:e.chapter,
										section:e.section,
										permis:this.p_id
									}
									_this.$store.dispatch('AddSection',obj).then(res=>{
										_this.dialogVisible2 = false
									})
								}, 300)
							}
						}
					}).catch(e=>{
						_this.$message.error('图片上传失败，请重新操作')
					})	
				}else{
					this.$notify({title: '提示', message: '请上传视频源', type: 'warning'});
				}
			}
		}
	}
</script>


<style>
	.btn{
		padding: 3px 5px;
	}
	.editorCourse{
		margin-top: 5vh!important;
		padding: 15px;
		width: 800px;
	}
	.editorCourseItem{
		margin-top: 4vh!important;
		padding: 15px;
		width: 800px;
	}
	.AddCourseItem{
		margin-top: 4vh!important;
		padding: 15px;
		width: 800px;
	}
	#UFM .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 130px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}
	
	#UFM .avatar {
		width: 130px;
		height: 80px;
		display: block;
	}
</style>
