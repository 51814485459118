<template>
	<el-row id="classCourse" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)">
		<el-col :span="24" style="padding: 05px 30px;font-size: 20px;color: #fff;">
			<i class="el-icon-discount" style="color: #13C2C2;font-weight: bold;"></i> {{Category.className}}
			<el-tag size="mini" type="primary" v-for="(list,idx) in Category.classTag" :key="idx" style="margin-left: 5px;">{{list}}</el-tag>
			<p style="font-size: 12px;color: #e7e7e7;">
				{{Category.instruct}}
			</p>
		</el-col>
		<el-col :span="24" style="padding: 0px 30px;">
			<el-col :span="6" v-for="(item,index) in courseList" :key="index" class="box" @click.native="OpenCourese(item)">
				<el-col :span="24" style="box-shadow: 0px 2px 5px rgba(9, 9, 9, 0.5);">
					<div class="demo-image__placeholder">
					    <div class="block">
						  <el-image :src="item.thumbnail" fit="contain"></el-image>
						</div>
					</div>
					<h5>{{item.coursename}}</h5>
					<p style="margin: 0px;height: 40px;">
						<el-tooltip class="item">
							<div slot="content" style="width: 200px;">{{item.introduce}}</div>
							<p class="introduce">{{item.introduce}}</p>
						</el-tooltip>
					</p>
					<p class="studyers">
						<span style="color: #666;">学习人数：{{item.studyers}} 人 · {{$timesDate(item.shelvestime)}}</span>
						<span class="permis">
							<span style="color: #11cbf0;" v-if="item.permis == 0" title="免费公开课">公开课程</span>
							<span style="color: #04ce08;" v-if="item.permis == 1" title="线上课程">线上课程</span>
							<span style="color: #ff521e;" v-if="item.permis == 2" title="社群学员专享">社群课程</span>
							<span style="color: #ffa508;" v-if="item.permis == 3" title="付费课程">付费课程</span>
						</span>
					</p>
				</el-col>
			</el-col>
			
		</el-col>
	
	</el-row>
</template>

<script>
	import {timestampToDate} from '@/utils/fun'
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				Category:{},
				courseList:[],
				loading:false
			}
		},
		mounted() {
			
			this.init()
			
			
		},
		methods: {
			timestampToDate(e){
				return timestampToDate(e*1000)
			},
			init(){
				this.loading = true
				this.states.CategoryData.forEach(item=>{
					if(item.classID == this.states.selected){
						this.Category = item
					}
				})
				// 获取指定分类的课程
				this.$store.dispatch('GetcourseCla',{classID:this.Category.classID}).then(res=>{
					this.courseList = res.data
					this.loading = false
				})
			},
			OpenCourese(e){
				if(JSON.stringify(this.states.userinfo) != '{}'){
					this.states.ispermis = false
					this.$store.dispatch('courseInfo',{course_id:e.course_id,uid:this.states.userinfo.id}).then(res=>{
						if(res.code == 200){
							this.states.playerSource = {course:e,chapter:this.OCURSECONTENT(res.data)}
							this.states.playerduring = this.OCURSECONTENT(res.data).chapter[0][0]
							this.states.ispermis = res.ispermis
							this.states.selected = 999
						}else{
							this.$notify({ title: '温馨提醒',message: '您还没获取学习本门课程的资格呢！',type: 'warning'});
						}
					})
				}else{
					this.$notify({ title: '温馨提醒',message: '请您先登录！',type: 'warning'});
				}
			},
			// 格式化课程章节表
			OCURSECONTENT(e){
				let arraysay = [],arr = [],cat = [],arrine = [],obj = {}
				e.forEach(item=>{
					cat.push(item.chapter)
				})
				arrine = Array.from(new Set(cat))
				//播放时长数组
				let timelengths = [],o = 0;
				arrine.forEach(item=>{
					e.forEach(list=>{
						if(list.chapter == item){
							list.show = false
							timelengths.push(list.timelength.split(/[分秒]/))
							arr.push(list)
							o ++;
						}
					})
					arraysay.push(arr)
					arr = []
				})
				let suour = 0
				//计算章节数  
				obj.chapternumber = arraysay.length
				obj.sectionnumber = o
				//计算时长总和
				timelengths.forEach(item=>{
					suour += parseInt(item[0]*60) + parseInt(item[1]===''?0:item[1])	
				})
				suour = suour/60/60
				// 总时长
				obj.timezong = suour.toFixed(2)
				obj.chapter = arraysay
				return obj
			},
		}
	}
</script>

<style>
	#classCourse {
		padding: 15px;
		height: calc(100vh - 100px);
		background-size:75px 75px;
	}
	#classCourse h5{
		width: 100%;
		margin: 0px;
		padding: 0px 4px;
		color: #dfdfdf;
		font-size: 14px;
	}
	#classCourse h5:hover{
		color: #FF6716;
	}
	#classCourse .box{
		padding: 0px 15px;
		cursor: pointer;
		margin-bottom: 15px;
	}
	#classCourse .permis{
		float: right;
	}
	#classCourse .introduce{
		margin: 5px;
		font-size: 12px;
		padding: 0px 5px;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #ebebeb;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	#classCourse .studyers{
		line-height: 22px;
		margin: 0px;
		padding: 0px 10px;
		padding-bottom: 5px;;
		font-size: 12px;
		color: #666;
		
	}

</style>
