<template>
	<div :style="'background-color: #ffffff;height:'+(states.Height-122)+'px'" id="chongzhi">
		<!-- 余额充值 -->
		<el-col :span="24" style="border-bottom: 1px solid #eee;padding: 10px 15px;">
			<el-col :span="14" style="height: 40px;font-size: 23px;line-height: 30px;font-weight: bold;color: #444;">余额充值</el-col>
			<el-col :span="10">
				<el-steps :space="150" :active="3" style="float: right;width: 240px;">
				  <el-step title="填写金额"></el-step>
				  <el-step title="扫码支付"></el-step>
				  <el-step title="支付完成"></el-step>
				</el-steps>
			</el-col>
		</el-col>
		<el-col :span="14">
			<el-col :span="24" style="height: 50px;line-height: 50px;padding: 10px;color: #535353;margin-bottom: 15px;">
				<div style="width: 200px;display: inline-block">账户ID：{{states.userinfo.nickname}} </div>
				<span>当前可用余额：￥{{states.userinfo.money}}</span>
			</el-col>
			<el-col :span="24" style="padding: 10px;">
				<el-form label-width="100" size="mini">
					<el-form-item label="充值金额">
						<el-input v-model="money" style="width: 200px;" onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))" type="number"></el-input>
						&nbsp;<el-button type="primary" @click="sbm">立即充值</el-button>
					</el-form-item>
				</el-form>
			</el-col>
			<el-col :span="24">
				<el-button class="ben-btn" type="primary" size="mini" @click="scoers(100)">￥100元</el-button>
				<el-button class="ben-btn" type="primary" size="mini" @click="scoers(300)">￥300元</el-button>
				<el-button class="ben-btn" type="primary" size="mini" @click="scoers(500)">￥500元</el-button>
				<el-button class="ben-btn" type="primary" size="mini" @click="scoers(800)">￥800元</el-button>
				<el-button class="ben-btn" type="primary" size="mini" @click="scoers(1000)">￥1000元</el-button>			
			</el-col>
			<el-col :span="24" style="margin-top: 45px;color: #777;font-size: 13px;">
				温馨提醒	:
				<p>1、余额可以在PC端进行学员认证和课程购买，但不可直接用于下载资源；</p>
				<p>2、充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；</p>
				<p>3、客服电话：400-037-0388 24小时电话；</p>
				<p>4、企业电话：0752-7213168 Time 8:30~18:00</p>
				<p>5、充值金额输入值必须是不小于100元不可大于5000元的正整数；</p>
				<p>6、本平台使用微信支付；一旦充值成功不可退款。</p>
				<p>7、充值完成后，您可以进入账户充值记录页面进行查看余额充值状态；</p>
			</el-col>	
		</el-col>	
		<el-col :span="10" style="margin-top: 30px;">
			<el-col :span="24" v-if="wxpayurl != ''">
				<div class="topupbox">
					<p class="topuptext" style="font-size: 17px;">微信收款码 {{formatSeconds(score)}}</p>
					<img :src="wxpayurl" class="topupimg">
					<p class="topuptext">支付即同意大师品牌余额充值协议</p>
				</div>
			</el-col>
		</el-col>






		<el-dialog title="微信支付" :visible.sync="dialogVisible" :before-close="handleClose" width='30%'>
			<div v-if="shows == 0">
				<div class="dialogzhifu">
					<span style="color: #008000;">用户账号：</span><span
						style="color:#BD2130;">{{$store.state.userinfo.mobile}}</span>
					<p style="color: #2D64B3;">您正在进行充值...</p>
					<p style="color: #2D64B3;">充值金额为 <b style="color: #218838; font-weight: bold; font-size: 23px;">
							{{money}} 元</b></p>
					<p style="color: #2D64B3;">请扫描下方二维码进行付款...</p>
				</div>
				<div style="text-align: center;">
					<img :src="wxpayurl" alt="" class="wximgs">
					<p>本支付由 <b>顺名科技有限公司</b> 提供技术支持</p>
					<p style="margin-top: 20px;">
						<button class="btn btn-info" style="border: none; background-color:#00B7EE; color: #FFFFFF;"
							@click="paycheck">我已付款</button>
					</p>
				</div>
			</div>


			<!-- 显示支付结果 -->
			<!-- 支付成功 -->
			<div v-if="shows == 1">
				<span style="color: #008000;margin-top:10px">用户账号：</span><span
					style="color:#BD2130;">{{$store.state.userinfo.mobile}}</span>
				<p style="color: #2D64B3;margin-top:10px">充值成功！</p>
				<p style="color: #2D64B3;margin-top:10px">充值金额为: <b
						style="color: #218838; font-weight: bold; font-size: 23px;">
						{{order.money_amount/100}} 元</b></p>
				<p style="color: #2D64B3;margin-top:10px">微信流水号:<b>{{order.wxpayorder}}</b></p>
				<p style="color: #2D64B3;margin-top:10px">充值成功时间:<b>{{order.endtime}}</b></p>
				<el-button type="primary" @click="backhome" style='margin-top:10px'>返回主页</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				// 0为正在充值 1为充值成功
				shows: 0,
				money: '',
				dialogVisible: false,
				wxpayurl: '',
				order: '',
				timer: '',
				score: 300,
				key:null
			}
		},
		methods: {
			backhome() {
				this.$router.push({
					path: '/home'
				})
			},
			handleClose() {
				this.dialogVisible = false
				console.log("关闭")
				// 跟踪用户操作
			},
			// 选择钱数
			scoers(e) {
				this.money = e;
			},
			test() {
				let reg = /^[+-]?(\d){1,}0$/;
			},
			// 支付结果校验
			paycheck() {
				this.score --
				if(this.score == 0){
					clearInterval(this.timer);
					this.wxpayurl = ''
					this.$notify.info({title: '消息',message: '收款码已失效，请重新生成！'});
				}else{
					if(this.score%2 == 1){
						this.$store.dispatch('PayListen', {uid:this.states.userinfo.id,key:this.key,money:this.states.userinfo.money}).then(res=>{
							if(res.code == 200){
								this.states.userinfo = res.data
								localStorage.setItem('userinfo', JSON.stringify(res.data));
								this.$store.dispatch('OTHERINFO')   // 用户额外信息
								clearInterval(this.timer);
								this.wxpayurl = ''
								this.$notify({title: '提示', message: '充值成功！',type: 'success'});
							}else{
								console.log(res.message)
							}
						})
					}
				}
				
				if(!this.key){
					clearInterval(this.timer);
					this.wxpayurl = ''
					this.$notify.info({title: '消息',message: '收款码异常，请重新生成！'});
				}
				
			},
			//生成订单 ---返回二维码
			sbm() {
				if (this.money == 0 || this.money < 0 && this.money > 5000) {
					this.money = ''
					this.$notify({title: '提示',message: '金额不能为空',type: 'warning'});
				} else {
					this.$store.dispatch('WxAddQr', {
						payType: 1,
						amount: this.money,
						uid: this.states.userinfo.id
					}).then(res => {
						this.key = res.key
						this.wxpayurl = 'data:image/jpeg;base64,'+res.data
						clearInterval(this.timer);
						this.timer = setInterval(this.paycheck, 1000);
						this.score = 300
					})
				}

			},
			formatSeconds(value) {
			    let result = parseInt(value)
			    let m = Math.floor((result / 60 % 60));
			    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
			    return `时效 ${m}:`+ `${s}`
			}
		},
		 beforeDestroy() {
		  clearInterval(this.timer);
		  this.wxpayurl = ''
		}
	}
</script>
<style>
	#chongzhi .topupimg{
		width: 240px;
		height: 240px;
		margin-top: 30px;
		margin-left: 29px;
		border-radius: 15px;
		border: 2px solid #ebfafa;
		opacity: 1;
	}
	#chongzhi .topuptext {
		color: #FFFFFF;
		margin: 0;
		position: absolute;
		text-align: center;
		width: 300px;
		padding-top: 5px;
		font-size: 13px;
	}
	#chongzhi .topupbox {
		margin-left: auto;
		margin-right: auto;
		width: 300px;
		height: 310px;
		background-color: #1f9fef;
	}

	#chongzhi{
		padding: 15px;
	}
	#chongzhi .el-step__title{
		color: #555;
		font-size: 12px;
		height: 20px;
		line-height: 20px;
	}
	#chongzhi .el-step__icon-inner{
		color: #555;
	}
</style>
<style>
	#chongzhi input::-webkit-outer-spin-button,
	#chongzhi input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0;
	}

	#chongzhi .dialogzhifu {
		padding-left: 40px;
		font-size: 20px;
	}

	#chongzhi .ben-btn {
		width: 80px;
	}

	@media (min-width:1500px) {
		#chongzhi .wximgs {
			width: 350px;
			height: 350px;
		}

		#chongzhi .el-dialog {
			width: 40%;
		}
	}

	@media (max-width:1100px) {
		#chongzhi .el-dialog {
			width: 60%;
		}
	}

	@media (max-width:700px) {
		#chongzhi .el-dialog {
			width: 90%;
		}
	}

	@media (max-width:550px) {
		#chongzhi .ben-btn {
			padding: 15px 25px;
		}

		#chongzhi .el-dialog {
			width: 100%;
		}

		#chongzhi .el-col {
			margin: 5px;
		}
	}

	@media (max-width:450px) {
		#chongzhi .ben-btn {
			padding: 10px 20px;
		}

		#chongzhi .dialogzhifu {
			padding-left: 20px;
			font-size: 20px;
		}
	}

	@media (max-width:350px) {
		#chongzhi .ben-btn {
			padding: 8px 12px;
		}
	}
</style>
