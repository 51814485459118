<template>
	<el-row :style="'background-color: #ffffff;height:'+(states.Height-130)+'px'">
		<el-col :span="24" v-if="JSON.stringify(BlogItem) == '{}'">
			<el-table :data="BlogData.slice((Page-1)*pagesize,Page*pagesize)" size="mini" :height="states.Height-168">
				<el-table-column prop="blogID" label="序号" width="60" align="center" />
				<el-table-column prop="title" label="标题" align="center" />
				<el-table-column label="封面" align="center">
					<template slot-scope="scope">
						<el-image style="width: 55px; height: 35px" :src="scope.row.posters"
							:preview-src-list="[scope.row.posters]">
							<div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="标签" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-tag size="mini" type="primary" v-for="(list,idx) in scope.row.blogTag" :key="idx" style="margin-left: 5px;">{{list}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="发布时间" width="130px">
					<template slot-scope="scope">
						{{Time(scope.row.dateTime*1000)}}
					</template>
				</el-table-column>
				<el-table-column prop="viewCounts" label="播放量" width="80px" />
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-tag type="primary" @click="lookEdit(scope.row)" size="small" style="margin-right: 10px;">编辑</el-tag>
						<el-tag :type="scope.row.softDelete == 0?'danger':'success'" @click="lookDel(scope.row)" size="small" style="margin-right: 10px;">{{scope.row.softDelete == 0?'下架':'发布'}}</el-tag>
					</template>
				</el-table-column>
			</el-table>
			<el-col :span="24" style="text-align: center;padding-top: 5px;">
				<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="Page"
					:page-sizes="[5,10,20,50]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
					:total="BlogData.length"></el-pagination>
			</el-col>
		</el-col>
		
		
		
		
		
		<el-col :span="24" v-if="JSON.stringify(BlogItem) != '{}'">
			<el-col :span="24">
				<el-button type="primary" size="mini" style="float: right;" @click="BlogItem = {}">返回列表</el-button>
			</el-col>
			<el-col :span="24" style="padding: 5px 100px;">
				<el-form label-width="100px" size="mini">
					<el-form-item label="标题">
						<el-input v-model="BlogItem.title" style="width: 80%;" />
					</el-form-item>
					<el-form-item label="标签">
						<el-input v-model="category" placeholder="Blog标签" style="width: 200px;" /> <el-button type="primary" size="mini" @click="BlogItem.blogTag.push(category),category = ''">添加</el-button>
						<el-tag v-for="(item,index) in BlogItem.blogTag" :key="index" size="small" style="margin-left: 15px;" @click="BlogItem.blogTag.splice(index,1)">{{item}}</el-tag>
					</el-form-item>
					<el-form-item label="Blog封面">
						 <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
							<span>标准比例为:1.61:1</span>
							<div class="uploadImg" @click="uploadImg1" v-loading="loading1">
								<span v-if="!BlogItem.posters">点击添加图片</span>
								<img :src="BlogItem.posters" class="uploadImg-img" v-if="BlogItem.posters">
							</div>
							</span>
							<input v-show="false" ref="input1" type="file" @change="handlechange">
						</el-col>
						<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
							<p v-if="BlogItem.respath" style="margin: 0px;">视频</p>
							<video :src="BlogItem.respath" controls="controls" width="330px" height="190px" v-if="BlogItem.respath"/>
						</el-col>
					</el-form-item>
					<el-form-item label="新视频源" v-if="file.url">
						<video :src="file.url" controls="controls" width="330px" height="190px" v-if="file.url"/>
					</el-form-item>
					<el-form-item label="视频上传"><input id="vid" type="file" class="getImgUrl_file" @change="preview"></el-form-item>
					<el-form-item label="视频原名" v-if="file.name">{{file.name}}</el-form-item>
					<el-form-item label="视频时长" v-if="file.timer">{{file.timer}}</el-form-item>
					<el-form-item v-if="statusText > 0">
						{{(statusText*100).toFixed(2)}}%<el-progress :percentage="Number(statusText * 100)" :status="statusText == 1?'success':'warning'"></el-progress>
					</el-form-item>
					<el-form-item>
						<el-button @click="authUpload()" type="success" size="small">保存</el-button>
					</el-form-item>
				</el-form>
			</el-col>
			
		</el-col>
		<video v-show="false" id="audio_id" src="" /><!-- 用于拿视频时间 -->
	</el-row>
</template>

<script>
	import {PostEditorFile} from '@/utils/request'
	import {timestampToTimes} from '@/utils/fun'
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				file:{url:'',file:{},name:'',newName:'',timer:0},				// temp 视频源
				category:'',			// tag
				BlogData:[],
				Page: 1,
				pagesize: 10,
				BlogItem:{},			// Blog 
				loading1:false,
				statusText:0,
				oss:{
					  region: 'oss-cn-shenzhen',
					  success_action_status: '200', 	
					  accessKeyId: 'LTAI4FsrLnoktYR84ahTbigL',  									
					  accessKeySecret: 'PqtmAi5Vh0fP9Z9Q9xGOfJ9bSEE73E',								
					  bucket: 'dscourse',
				}
			}
		},
		mounted() {
			this.client = new OSS(this.oss)
			this.init()
		},
		methods: {
			Time(e){
				return timestampToTimes(e)
			},
			// 初始化
			init() {
				this.$store.dispatch('GetBlogAll').then(res => {
					res.data.forEach(item=>{
						item.blogTag = JSON.parse(item.blogTag)
					})
					this.BlogData = res.data
				})
			},
			SizeChange(val) {
				this.pagesize = val
			},
			CurrentChange(val) {
				this.Page = val
			},
			// 编辑
			lookEdit(event){
				this.BlogItem = event
			},
			// 上传图片到阿里云
			uploadImg1() {
				this.$refs.input1.click()
			},
			handlechange(e) {
				this.loading1 = true
				let file = e.target.files[0]
				// 后缀名
				const filesuffix = file.name.split('.')[1]
				const pathname = `cover_${new Date().getTime()}.${filesuffix}`
				PostEditorFile(file, pathname).then(res => {
					this.loading1 = false
					this.BlogItem.posters = res.data.data
				})
			},
			// 选择视频
			preview() {
				if (document.getElementById('vid').files[0]) {
					this.file.file = document.getElementById('vid').files[0]
					this.file.url = this.getObjectURL(document.getElementById('vid').files[0])
					this.file.name = document.getElementById('vid').files[0].name
					// 获取视频大小
					this.getvideotime(document.getElementById('vid').files[0]).then(res => {
						this.file.timer = res
						this.file.newName = 'video/Blog_' + new Date().getTime()+'.mp4'
					})
				}
			},
			getObjectURL(file) {
				let url = null
				if (window.createObjectURL !== undefined) {
					url = window.createObjectURL(file)
				} else if (window.webkitURL !== undefined) {
					url = window.webkitURL.createObjectURL(file)
				} else if (window.URL !== undefined) {
					url = window.URL.createObjectURL(file)
				}
				return url
			},
			// 获取每个视频的播放时长
			getvideotime(file) {
				return new Promise((resolve, reject) => {
					const url = URL.createObjectURL(file)
					const videos = document.getElementById('audio_id')
					videos.src = url
					videos.oncanplay = function() {
						let timer = 0
						timer = Number(videos.duration)
						timer = timer.toFixed(0)
						const minute = Math.floor(timer / 60)
						const seconds = Math.floor(timer % 60)
						timer = `${minute}分${seconds}秒`
						resolve(timer)
					}
				})
			},
			// 更新
			authUpload(){
				let _this = this
				if(this.file.url == ''){
					// 不更新视频
					this.$store.dispatch('ModifyBlog',this.BlogItem).then(res => {
						if(res.code == 200){
							this.init()
							this.BlogItem = {}
						}
					})
				}else{
					_this.client.multipartUpload(_this.file.newName, _this.file.file, {
					  progress(p, checkpoint) {
						_this.statusText = Number(p)
						// 请求写入数据库
						if (p == 1) {
							_this.BlogItem.respath = 'https://dscourse.oss-cn-shenzhen.aliyuncs.com/' + _this.file.newName
							_this.$store.dispatch('ModifyBlog',_this.BlogItem).then(res => {
								if(res.code == 200){
									_this.init()
									_this.BlogItem = {}
								}
							})
						}
					  }
					}).catch(e=>{
						this.$notify.info({title: '消息',message: '上传已暂停'});
					})
					
				}
				
			},
			// 删除
			lookDel(event){
				event.softDelete = event.softDelete  == 0?1:0
				this.$store.dispatch('ModifyBlog',event).then(res => {
					if(res.code == 200){
						this.init()
					}
				})
			}
		}
	}
</script>

<style>

</style>
