<template>
	<el-row :style="'background-color: #ffffff;height:'+(states.Height-72)+'px'" id="ModifyUserInfo">
		<el-col :span="24" style="padding: 15px;">
			<el-form label-width="80px" size="mini">
				<el-form-item label="用户名">
					<el-input v-model="ds_name"></el-input>
				</el-form-item>
				<el-form-item label="操作">
					<el-button type="primary"  @click="submitForm(ds_name)">修改</el-button>
				</el-form-item>
			</el-form>
		</el-col>
		<!-- <el-col :span="24" style="padding: 15px;">
			{{states.address}}
		</el-col> -->
		<!-- <el-col :span="12" style="padding: 15px;">
			<el-form label-width="80px" size="mini">
				<el-form-item label="模板名称" prop="name">
					<el-input v-model="ruleForm.name"></el-input>
				</el-form-item>
				<el-form-item label="收件人" prop="addname">
					<el-input v-model="ruleForm.addname"></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="phone">
					<el-input v-model="ruleForm.phone"></el-input>
				</el-form-item>
				<el-form-item label="邮编">
					<el-input v-model="ruleForm.zip"></el-input>
				</el-form-item>
				<el-form-item label="称谓" prop="sex">
					<el-radio-group v-model="ruleForm.sex">
						<el-radio label="男士"></el-radio>
						<el-radio label="女士"></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="地址">
					<el-cascader size="large" :options="options" v-model="selectedOptions" @change="addressChange">
					</el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" prop="address">
					<el-input type="textarea" v-model="ruleForm.address"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary"  @click="submitForm('ruleForm')">立即添加</el-button>
					<el-button @click="cancel">取消</el-button>
				</el-form-item>
			</el-form>
						
		</el-col> -->
				
		
	
	</el-row>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				ds_name:''
			}
		},
		mounted() {
		  this.ds_name	= this.states.userinfo.ds_name
		},
		methods: {
			// 修改
			submitForm(e){
				this.$store.dispatch('ModifyUser',{ds_name:e,uid:this.states.userinfo.id}).then(res=>{
					this.states.userinfo.ds_name = e
				})
			}
		}
	}
</script>

<style>
	#ModifyUserInfo .avatar-uploader .el-upload {
	    border: 3px solid #d9d9d9;
	    border-radius:50%;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
	  }
	#ModifyUserInfo .avatar-uploader .el-upload:hover {
	    border-color: #409EFF;
	  }
	#ModifyUserInfo .avatar-uploader-icon {
	    font-size: 28px;
	    color: #8c939d;
	    width: 80px;
	    height: 80px;
	    line-height: 80px;
	    text-align: center;
	  }
	#ModifyUserInfo .avatar {
	    width: 80px;
	    height: 80px;
	    display: block;
	  }
	#ModifyUserInfo .el-card__body{
		  padding: 15px;
	  }
</style>
