<template>
      <div class="upload-type">
		<el-button v-if="show == 0" size="small" type="primary" @click="show = 1">确认发布</el-button>
        <el-button v-if="show == 1" @click="authUpload" type="success" size="small">开始上传</el-button>
        <el-button v-if="show == 2" @click="pauseUpload" type="info" size="small">暂停</el-button>
        <el-button v-if="show == 3" @click="resumeUpload(getReady[0])" type="warning" size="small">恢复上传</el-button>
		<el-button v-if="show == 4" size="small" type="primary" @click="states.CourseTab = 'published'">查看课程</el-button>
        <b>剩余待上传的视频数量：{{ alllength }}&nbsp;&nbsp;</b>
      </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
  	...mapGetters(['states'])
  },
  data() {
    return {
	  timer:null,			// 定时器
      show: 0,				// 按钮控制
      alllength: 0, 		// 所有视频长度
	  getReady:[],
    }
  },
  watch: {
    alllength(newval) {
      if (newval < 0) {
        this.alllength = 0
      }
      if (newval == 0) {
		 this.show = 4
		 this.states.CourseTab = 'published'
		 this.$notify({title: '提示',message: '全部视频已上传完毕',type: 'success'});
      }
    }
  },
  mounted(){
	clearInterval(this.timer)  
	this.states.coursechild.forEach((item,i)=>{
		item.forEach((list,j)=>{
			list.cache = {}
			list.state = 0
			list.i = i+1
			list.j = j+1
			list.courseID = this.states.newcourse.course_id
			list.permis = this.states.newcourse.permis
			list.source.newName = `video/${this.states.newcourse.course_id}_${i+1}_${j+1}_${new Date().getTime()}.mp4`
			this.alllength ++
		})
	})
  },
  methods: {
	// 开始上传
	authUpload() {
		this.timer = setInterval(this.upload, 3000);  
		this.show = 2
	},
	// 视频上传 规则 判断当前视频完成后在进行下一条上传
	upload(){
		this.states.coursechild.forEach((item,i)=>{
			item.forEach((list,j)=>{
				if(list.state == 0){
					if(this.getReady.length == 0){
						this.getReady.push(list)
						this.Ready()
					}
					
				}
				this.allComplete()
			})
		})
		console.log("定时器",new Date().getTime()/1000)
	},
	// 处理单个视频的上传结束后清除
	Ready(){
		this.multipartUpload(this.getReady[0]).then(res=>{
			this.getReady[0].state = 1
			this.createcourseware(this.getReady[0])  // 入库
			this.getReady = []
			this.alllength --
		})
	}, 
	 // 判断是否全部上传
	allComplete(){
		let arr = []
		this.states.coursechild.forEach((item,i)=>{
			item.forEach((list,j)=>{
				if(list.state == 0){
					arr.push(list)
				}
			})
		})
		if(arr.length == 0){
			this.$store.dispatch('GetCourses').then(res=>{
				clearInterval(this.timer)
				this.states.allcourses = res.data
				this.states.allshowcorser = true
				this.states.newcourse = {}
				this.states.allshowcorser = true
				this.states.coursechild = []
				this.states.allcourdernei = []
				this.states.createstate = 1  
				this.states.ruleForm = {
					coursename: '', 							// 课程名称
					courseteacher: '', 							// 授课老师
					coursecategory: '', 						// 课程类别 
					courseintroduce: '', 						// 课程介绍
					coverimg: '', 								// 课程海报
					covimg: {url: ''}, 							// 覆盖ID
					courseimg: '', 								// 课程海报
					delimg: {url: ''}, 							// 覆盖ID
					paycourse: false, 							// 是否付费开关	
					pricecourse: '', 							// 课程价格--在切换成付费课后显示
					type: '' 									// 权限组
				}
			})
		}else{
			console.log('剩余未上传',arr.length)
		}
	}, 
	// 暂停上传
	pauseUpload() {
	  this.show = 3
	  this.client.cancel()
	}, 
    // 恢复上传
    resumeUpload(event) {
      this.resumeUploads(event).then(res=>{
		  this.getReady[0].state = 1
		  this.createcourseware(this.getReady[0])  // 入库
		  this.getReady = []
		  this.alllength --
	  })
	  this.show = 2
    },
	// 开始上传
    multipartUpload(event) {
		return new Promise((resolve, reject) => {
			let _this = this
			
			this.client.multipartUpload(event.source.newName, event.source.file, {
			  progress(p, checkpoint) {
				  event.cache = checkpoint
				event.source.statusText = Number(p)
				// 请求写入数据库
				if (p == 1) {
				  resolve({code:200})	
				}
			  }
			}).catch(e=>{
				this.$notify.info({title: '消息',message: '上传已暂停'});
			})
		})
    },
	// 恢复上传
    resumeUploads(event) {
		return new Promise((resolve, reject) => {
			this.client.multipartUpload(event.cache.name, event.cache.file, {
			  progress(p, checkpoint) {
				event.cache = checkpoint
				event.source.statusText = Number(p)
				if (p == 1) {
				  resolve({code:200})	
				}
			  }
			}).catch(e=>{
				this.$notify.info({title: '消息',message: '上传已暂停1'});
			})
		})
    },
    // 编辑课程视频录入数据库
    createcourseware(event) {  
		let {i,j,title,intro,cover,courseID,permis} = event;
		let obj = {course_id:courseID,chapter:i,section:j,abstract:title,
			respath:'https://dscourse.oss-cn-shenzhen.aliyuncs.com/'+event.source.newName,
			instructions:intro,cover:cover,timelength:event.source.timer,permis:permis
		}
		this.$store.dispatch('PutSection',obj).then(res=>{
			console.log("入库结果",res)
		})
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>
