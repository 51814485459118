<template>
	<el-col :span="24" class="tab_List">
		<ul>
			<li :class="states.selected == 1?'activity':''" @click="switchover(1)"><i class="el-icon-c-scale-to-original"></i> 推荐</li>
			<li :class="states.selected == 2?'activity':''" @click="switchover(2)"><i class="el-icon-video-camera"></i> BLOG</li>
			<li v-for="(item,index) in states.CategoryData" :key="index" :class="states.selected == item.classID?'activity':''" @click="switchover(item.classID)">
				<!-- <img :src="item.classIcon" style="width:15px;height: 15px;"> -->
				{{item.className}}
			</li>
			<li :class="states.selected == 3?'activity':''" v-if="states.islogin"  @click="switchover(3)"><i class="el-icon-collection-tag"></i> 上次观看</li>
			<li :class="states.selected == 5?'activity':''" v-if="states.islogin" @click="switchover(5)"><i class="el-icon-notebook-2"></i> 我的课程</li>
			<li :class="states.selected == 6?'activity':''" v-if="states.islogin" @click="switchover(6)"><i class="el-icon-star-off"></i> 我的收藏</li>
		</ul>
			
		<el-row class="blok blok-n blogroll">
			<p>
				<a href="#">关于我们</a>&nbsp;&nbsp;&nbsp;&nbsp;
				<a href="#">投诉建议</a>
			</p>
			<p><a href="https:mizhu.com/" title="迷猪技术有限公司" target="_blank">迷猪技术有限公司</a></p>
			<p><a href="https:shunming.com/" title="顺名科技有限公司" target="_blank">顺名科技有限公司</a></p>
			<p><a href="https:haobiao.com/" title="商标查询-豪标网" target="_blank">商标查询-豪标网</a></p>
			<p><a href="https://system.shunming.com/" title="商标注册-自助提交" target="_blank">商标注册-自助提交</a></p>
		</el-row>
		<el-col :span="24" style="padding:0px 10px;height: 300px;" v-if="states.userinfo.id == 1">
			<ul>
				<li :class="states.selected == 1000?'activity':''" @click="states.selected = 1000"><i class="el-icon-upload"></i> 课程上传</li>
				<li :class="states.selected == 1002?'activity':''" @click="states.selected = 1002"><i class="el-icon-upload"></i> 博客上传</li>
				<li :class="states.selected == 1001?'activity':''" @click="states.selected = 1001"><i class="el-icon-user-solid"></i> 用户管理</li>
				<li :class="states.selected == 1003?'activity':''" @click="states.selected = 1003"><i class="el-icon-s-grid"></i> 课程分类</li>
			</ul>
		</el-col>
	</el-col>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				
			}
		},
		mounted() {

		},
		methods: {
			switchover(e){
				this.states.selected = 0
				setTimeout(() =>{
				    this.states.selected = e
					if(this.states.selected == 2){
						this.states.playSoutce = {}
						
					}
				},200);
			},
			
		}
	}
</script>

<style>
	#TAB ul {
		padding-left: 0px;
		list-style: none;
		padding:0px;
		margin-top: 0px;
	}
	#TAB ul>li {
		padding: 0px 20px;
		height: 40px;
		line-height: 40px;
		cursor: pointer;
		font-size: 15px;
		color: #cfcfcf;
		font-family: "SimHei";
	}
	#TAB ul>li:hover {
		background-color: #2A2C37;
		color: #fff;
		border-bottom-right-radius: 20px;
		border-top-right-radius: 20px;
	}
	.blok {
		border-bottom: 1px solid #EEEEEE;
	}
	.blok-n {
		border-bottom: none;
	}
	.blok h5 {
		font-size: 15px;
		margin: 5px;
		padding: 5px;
	}



	.blogroll {
		padding: 15px;
		font-size: 12px;
	}

	.blogroll p {
		color: #cfcfcf;
		opacity: .9;
	}

	.blogroll a {
		color: #cfcfcf;
		opacity: .9;
	}

	.blogroll a:hover {
		color: #ffffff;
		opacity: 1;
	}
	.item_p{
		text-align: center;
		height: 20px;
		padding: 0px;
		margin: 0px;
		font-size: 12px;
	}
	.item_li{
		padding:0px 3px!important;
		line-height: 50px!important;
		height: 68px!important;
	}
	.item_p i{
		font-size: 20px;
	}
	.userInfo{
		height: 235px;
		padding: 5px 15px;
		margin: 0px;
	}
	/* 头像 */
	.u_content{
		border-top: 1px solid #EEEEEE;
	}
	.activity{
		background-color: #3c3f4f;
		border-bottom-right-radius: 20px;
		border-top-right-radius: 20px;
		color: #fff;
	}
	
	
</style>
