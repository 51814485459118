<template>
	<div class="login-box">
	<!-- 	<p class="login-t">
			Login {{show?'微信登陆':'账号登录'}} 
			<i class="el-icon-loading" v-if="show"></i>
			<i class="el-icon-close i" @click="states.LoginShow = false"></i>
		</p>
		<el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="65px" class="demo-loginForm login_from" size="medium" v-if="!show">
			<el-form-item label="手机号" prop="phone">
				<el-input v-model="loginForm.phone" placeholder="手机号码" type='number' class="deal"></el-input>
			</el-form-item>
			<el-form-item label="验证码">
				<el-input placeholder="验证码" v-model="loginForm.code" style="width: 315px;">
				    <template slot="append">
						 <el-button type="text" @click.native="send(loginForm.phone)" size="mini" style="width: 50px;" v-if="score == 0">发送</el-button>
						 <span v-if="score != 0">{{score}}</span>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label-width="0" style="text-align: center;">
				<el-button type="primary" @click="submitForm(loginForm)" size="small">登录</el-button>
				<el-button type="success" size="small" @click="GetWeCharQr">微信登陆</el-button>
			</el-form-item>
			<p class="writing">登录，注册即代表同意<span class="bule">《服务条款》</span></p>
		</el-form>
		
		<div class="login_QR" v-if="show">
			<img :src="loginQr" style="width: 160px;height: 160px;">
			<el-button type="primary" @click="userfun" size="small">账号登陆</el-button>
			<p class="writing">登录，注册即代表同意<span class="bule">《服务条款》</span></p>
		</div> -->
		
		
		<p class="login-t">
			Login 微信登陆/注册
			<i class="el-icon-loading" v-if="show"></i>
			<i class="el-icon-close i" @click="states.LoginShow = false"></i>
		</p>
		<div class="login_QR" >
			<img :src="loginQr" v-if="loginQr" style="width: 190px;height: 190px;">
			<p class="writing">登录，注册即代表同意<span class="bule">《服务条款》</span></p>
		</div>
	</div>
</template>
<script>
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				score:0,            // 验证码时间
				timer:null,			// 验证码计时器
				show:false,         // 验证码显示器
				loginForm: {
					phone: '13229493323',
					code: ''
				},
				//验证规则
				rules: {
					phone: [{required: true,message: '请输入用户名',trigger: 'blur'},{min: 11,max: 11,message: '请输入正确的手机号',trigger: 'blur'}]
				},
				loginQr:'',
				imgKey:'',
				score1:0,            // 验证码时间
				timer1:null,			// 验证码计时器
			}
		},
		mounted() {
			this.loginForm.code = ''
			clearInterval(this.timer);
			// 回车
			var _this = this;
			document.onkeydown = function(e) {
				var key = window.event.keyCode;
				if (key == 13) {
					document.getElementById('login').click()
				}
			}
			this.GetWeCharQr()
		},
		destroyed() {
		    clearInterval(this.timer);
			clearInterval(this.timer1);
		},
		methods: {
			userfun(){
				this.show = false
				clearInterval(this.timer);
				clearInterval(this.timer1);
			},
			// 验证码计时器
			timing(){
				this.score -= 1
				if(this.score < 1){
					clearInterval(this.timer);
					this.states.LoginShow = false
					this.loginForm.code = ''
					this.$notify({ title: '提示', message: '验证码失效', type: 'warning' });
				}
			},
			// 发送验证码
			send(event){
				this.$store.dispatch('RegCode',event).then(res=>{
					if(res.code == '200'){ 
						this.score = parseInt(res.data.time/1000);
						this.timer = setInterval(this.timing,1000);
					}
				})
			},
			submitForm(loginForm) {
				this.$refs.loginForm.validate((valid) => {
					if(valid){
						if(loginForm.code){
							this.$store.dispatch('Login',{phone:loginForm.phone,code:loginForm.code}).then(response=>{
								localStorage.clear(); //清空本地缓存
								this.states.userinfo = response.data
								localStorage.setItem('userinfo', JSON.stringify(response.data));
								this.states.LoginShow = false
								this.states.islogin = true;
								this.loginForm.code = ''
								this.$store.dispatch('OTHERINFO')   // 用户额外信息
								clearInterval(this.timer);
							})
						}else{
							this.$notify({ title: '提示', message: '请填写验证码', type: 'error' });
						}
					}
				})
			},
			// 微信登录二维码
			GetWeCharQr(){
				this.$store.dispatch('WeChatQR').then(response=>{
					this.loginQr = response.data.img
					this.imgKey = response.data.key
					this.score1 = 60
					this.timer1 = setInterval(this.QRtiming,2000);
					// 启动定时器
					this.show = true
				})
			},
			// 验证码计时器
			QRtiming(){
				this.score1 -= 1
				console.log(this.score1)
				if(this.score1 < 1){
					clearInterval(this.timer1);
					this.GetWeCharQr()
				}else{
					this.$store.dispatch('verifyQR',{key:this.imgKey}).then(response=>{
						if(response.data.code == '200'){
							localStorage.clear(); // 清空本地缓存
							this.states.userinfo = response.data.data
							localStorage.setItem('userinfo', JSON.stringify(response.data.data));
							this.states.islogin = true;
							this.states.LoginShow = false
							this.show = false
							this.loginForm.code = ''
							this.loginQr = ''
							this.imgKey = ''
							this.$store.dispatch('OTHERINFO')   // 用户额外信息
							clearInterval(this.timer1);
							this.$notify({title: '提示',message: '登录成功',type: 'success'});
						}
						
					})
				}
				
			},
		}
	}
</script>

<style scoped>
	.login-box {
		padding: 10px 0px;
	}

	.login-t {
		height: 40px;
		line-height: 40px;
		margin: 0px;
		padding: 0px;
		border-bottom: 1px solid #EEEEEE;
		padding-left: 15px;
	}
	.login-t .i {
		position: absolute;
		right: 22px;
		font-size: 20px;
		line-height: 35px;
		cursor: pointer;
	}

	.login_from {
		float: left;
		width: 380px;
		padding: 10px;
	}
	.login_QR {
		text-align: center;
		width: 360px;
		padding: 10px;
		margin-left: 10px;
		padding-top: 20px;
		position: relative;
	}
	
	.bule {
		color: #4f75ff;
		cursor: pointer;
	}

	.writing {
		padding: 0;
		margin: 0;
		width: 380px;
		height: 26px;
		line-height: 26px;
		text-align: center;
		font-size: 12px;
		color: #666;
		position: absolute;
		bottom: 10px;
	}
</style>
