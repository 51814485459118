<template>
	<el-row id="favorite" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)">
		<el-col :span="24" style="color: #ebebeb;">
			<span style="padding: 5px 15px;border-bottom: 1px solid #303030;"><i class="el-icon-star-off"></i> 收藏夹</span>
		</el-col>
		<el-col :span="24" class="favorite_list">
			<el-col :span="6" v-for="(item,index) in merge(course,states.blog)" :key="index" class="box" @click.native="OpenCourese(item,index)">
				<div class="demo-image__placeholder">
				  <div class="block"><el-image :src="(item.types =='kc'?item.thumbnail:item.posters)"></el-image></div>
				</div>
				<h5>
					<span v-if="item.types == 'kc'" style="font-size: 12px;color: #636363;float: right;">课程</span>
					<span v-if="item.types == 'bk'" style="font-size: 12px;color: #636363;float: right;" >BLOG</span>
					{{item.types =='kc'?item.coursename:item.title}}
				</h5>
				<p class="studyers" >
					<span >{{$timestampToTimes(item.idx)}}</span>
					<span class="permis" v-if="item.types == 'kc'">
						<span style="color: #11cbf0;" v-if="item.permis == 0" title="免费公开课">公开课程</span>
						<span style="color: #04ce08;" v-if="item.permis == 1" title="线上课程">线上课程</span>
						<span style="color: #ff521e;" v-if="item.permis == 2" title="社群学员专享">社群课程</span>
						<span style="color: #ffa508;" v-if="item.permis == 3" title="付费课程">付费课程</span>
					</span>
				</p>
				<p class="studyers1" v-if="item.types == 'bk'">
					<span class="permis">
						<span v-for="(list,idx) in item.blogTag" :key="idx" style="padding-top: 3px;margin-right: 10px;display: inline-block;">{{list}}</span>
					</span>
				</p>
			</el-col>
		</el-col>
	</el-row>
</template>

<script>
	import {timestampToDate,OCURSECONTENT} from '@/utils/fun'
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				course:[],
				show:true,
				loading:false
			}
		},
		mounted() {
			this.init(this.states.mycuorse)
		},
		methods: {
			// 合并
			merge(arr1,arr2){
				if(arr1.length > 0){
					arr1.forEach(item=>{
						item.types = 'kc'
						this.states.mycuorse.forEach(list=>{
							if(list.collect_id == item.course_id){
								item.idx = list.times
							}
						})
					})
				}
				if(arr2.length > 0){
					arr2.forEach(item=>{
						item.types = 'bk'
						this.states.mycuorse.forEach(list=>{
							if(list.collect_id == item.blogID){
								item.idx = list.times
							}
						})
					})
				}
				return arr1.concat(arr2).sort((a, b) => b.idx - a.idx)
			},
			OpenBlog(e){
				this.states.selected = 2
				this.states.favor = e	
			},
			OpenCourese(e){
				if(e.types == 'kc'){
					if(this.states.islogin){
						this.states.ispermis = false
						this.$store.dispatch('courseInfo',{course_id:e.course_id,uid:this.states.userinfo.id}).then(res=>{
							if(res.code == 200){
								this.states.playerSource = {course:e,chapter:OCURSECONTENT(res.data)}
								this.states.playerduring = OCURSECONTENT(res.data).chapter[0][0]
								this.states.ispermis = res.ispermis
								this.states.selected = 999
							}else{
								this.$notify({ title: '温馨提醒',message: '您还没获取学习本门课程的资格呢！',type: 'warning'});
							}
						})
					}else{
						this.$notify({ title: '温馨提醒',message: '请您先登录！',type: 'warning'});
					}
				}else{
					this.OpenBlog(e)
				}
			},
			timestampToDate(e){
				return timestampToDate(e*1000)
			},
			init(e){
				
				this.loading = true
				let obj = {course:[],blog:[]}
				if(e.length > 0){
					e.forEach(item=>{
						if(item.type == 1){
							obj.course.push(item.collect_id)
						}else if(item.type == 2){
							obj.blog.push(item.collect_id)
						}
					})
				}
				// 换取收藏信息
				this.$store.dispatch('Collect',obj).then(res=>{
					if(res.code == '200'){
						this.course =  res.data.course
						this.states.blog =  res.data.blog
						this.states.playSoutce = {}
					}
					this.loading = false
				})
			}
		}
	}
</script>

<style>
	.favorite_list{
		overflow: auto;
	}
	.favorite_list::-webkit-scrollbar {
		width: 4px!important;
	}
	.favorite_list::-webkit-scrollbar-thumb {
		border-radius: 7px;
		-webkit-box-shadow: inset 0 0 5px rgba(255,103,22, 0.5);
		background: rgba(255,103,22, 0.5);
	}
	
	.favorite_list::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 1.0);
		border-radius: 0;
		background: rgba(207, 207, 207, 0.2);
	}
	#favorite {
		height: calc(100vh - 72px);
	}
	#favorite .box{
		padding: 15px;
		cursor: pointer;
		position: relative;
	}
	#favorite .permis{
		float: right;
	}
	#favorite .studyers{
		height: 22px;
		line-height: 22px;
		margin: 0px;
		padding: 5px 10px;
		padding-bottom: 0px;;
		font-size: 12px;
		color: #c1c1c1;
	}
	#favorite h5{
		margin: 0px;
		padding: 4px;
		color: #e5e5e5;
		font-size: 14px;
	}
	#favorite h5:hover{
		color: #FF6716;
	}
	#favorite .studyers1{
		position: absolute;
		font-size: 12px;
		color: #ececec;
		bottom: 61px;
		right: 15px;
		height: 20px;
		width: calc(100% - 30px);
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7));
		text-shadow: 2px 5px 10px rgba(0, 0, 0, 1);
	}
</style>
