<template>
	<div style="padding:0; width: 100%;">
		<!-- 创建课程 -->
		<el-alert :closable="false" title="BLOG发布" style="margin-bottom: 10px;" type="success" />
		<el-form ref="BlogForm" :model="states.BlogForm" :rules="rules" label-width="100px" class="demo-ruleForm" size="mini">
			<el-form-item label="标题" prop="Blogname">
				<el-input v-model="states.BlogForm.Blogname" />
			</el-form-item>
			<el-form-item label="标签">
				<el-input v-model="category" placeholder="Blog标签" style="width: 200px;" /> <el-button type="primary" size="mini" @click="states.BlogForm.category.push(category),category = ''">添加</el-button>
				<el-tag v-for="(item,index) in states.BlogForm.category" :key="index" size="small" style="margin-left: 15px;" @click="states.BlogForm.category.splice(index,1)">{{item}}</el-tag>
			</el-form-item>
			<el-form-item label="Blog封面">
				 <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<span>标准比例为:1.61:1</span>
					<div class="uploadImg" @click="uploadImg1" v-loading="loading1">
						<span v-if="!states.BlogForm.coverimg">点击添加图片</span>
						<img :src="states.BlogForm.coverimg" class="uploadImg-img" v-if="states.BlogForm.coverimg">
					</div>
					</span>
					<input v-show="false" ref="input1" type="file" @change="handlechange">
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
					<p v-if="states.BlogForm.sourceUr" style="margin: 0px;">视频源</p>
					<video :src="states.BlogForm.sourceUr" controls="controls" width="330px" height="190px" v-if="states.BlogForm.sourceUr"/>
				</el-col>
			</el-form-item>
			<el-form-item label="视频上传"><input id="ids" type="file" class="getImgUrl_file" @change="preview"></el-form-item>
			<el-form-item label="视频原名">{{states.BlogForm.Playname}}</el-form-item>
			<el-form-item label="视频时长">{{states.BlogForm.timer}}</el-form-item>
			<el-form-item v-if="statusText > 0">
				{{(statusText*100).toFixed(2)}}%<el-progress :percentage="Number(statusText * 100)" :status="statusText == 1?'success':'warning'"></el-progress>
			</el-form-item>
			<el-form-item>
				<el-button @click="authUpload('BlogForm')" type="success" size="small">开始上传</el-button>
				<el-button @click="resetForm('BlogForm')" size="small">重置</el-button>
			</el-form-item>
		</el-form>
		
		<video v-show="false" id="audio_id" src="" /><!-- 用于拿视频时间 -->
	</div>
</template>

<script>
	import {PostEditorFile} from '@/utils/request'
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states']),
		},
		data() {
			return {
				category:'',
				dialogTableVisible: false,
				rules: {
					Blogname: [{
						required: true,
						message: '请输入标题',
						trigger: 'blur'
					}, {
						min: 1,
						max: 50,
						message: '长度在 1 到 50 个字符',
						trigger: 'blur'
					}]
				},
				loading1: false,
				statusText:0,		// 上传进度
				client: '',			// OSS
				oss:{
					  region: 'oss-cn-shenzhen',
					  success_action_status: '200', 	
					  accessKeyId: 'LTAI4FsrLnoktYR84ahTbigL',  									
					  accessKeySecret: 'PqtmAi5Vh0fP9Z9Q9xGOfJ9bSEE73E',								
					  bucket: 'dscourse',
				}
			}
		},
		mounted() {
			this.client = new OSS(this.oss)
		},
		methods: {
			// 选择视频
			preview() {
				if (document.getElementById('ids').files[0]) {
					this.states.BlogForm.file = document.getElementById('ids').files[0]
					this.states.BlogForm.sourceUr = this.getObjectURL(document.getElementById('ids').files[0])
					this.states.BlogForm.Playname = document.getElementById('ids').files[0].name
					// 获取视频大小
					this.getvideotime(document.getElementById('ids').files[0]).then(res => {
						this.states.BlogForm.timer = res
						this.states.BlogForm.name = 'video/Blog_' + new Date().getTime()+'.mp4'
					})
				}
			},
			getObjectURL(file) {
				let url = null
				if (window.createObjectURL !== undefined) {
					url = window.createObjectURL(file)
				} else if (window.webkitURL !== undefined) {
					url = window.webkitURL.createObjectURL(file)
				} else if (window.URL !== undefined) {
					url = window.URL.createObjectURL(file)
				}
				return url
			},
			resetForm(formName) {
				this.$refs[formName].resetFields()
			},
			// 获取每个视频的播放时长
			getvideotime(file) {
				return new Promise((resolve, reject) => {
					const url = URL.createObjectURL(file)
					const videos = document.getElementById('audio_id')
					videos.src = url
					videos.oncanplay = function() {
						let timer = 0
						timer = Number(videos.duration)
						timer = timer.toFixed(0)
						const minute = Math.floor(timer / 60)
						const seconds = Math.floor(timer % 60)
						timer = `${minute}分${seconds}秒`
						resolve(timer)
					}
				})
			},
			// 上传图片到阿里云
			uploadImg1() {
				this.$refs.input1.click()
			},
			handlechange(e) {
				this.loading1 = true
				let file = e.target.files[0]
				// 后缀名
				const filesuffix = file.name.split('.')[1]
				const pathname = `cover_${new Date().getTime()}.${filesuffix}`
				PostEditorFile(file, pathname).then(res => {
					this.loading1 = false
					this.states.BlogForm.coverimg = res.data.data
				})
			},
			
			
			// 开始上传
			authUpload(formName) {
				let _this = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(_this.states.BlogForm.coverimg != ''){
							if(_this.states.BlogForm.sourceUr != '' && _this.states.BlogForm.file != ''){
								_this.client.multipartUpload(this.states.BlogForm.name, this.states.BlogForm.file, {
								  progress(p, checkpoint) {
									_this.statusText = Number(p)
									// 请求写入数据库
									if (p == 1) {
										let OBJ = _this.states.BlogForm
										OBJ.name = 'https://dscourse.oss-cn-shenzhen.aliyuncs.com/' + OBJ.name
										delete OBJ.file
										_this.$store.dispatch('PutBlog',OBJ).then(res=>{
											if(res.code == 200){
												_this.states.BlogForm = {Blogname: '',category: '',coverimg: '',sourceUr: '',Playname:'',name:'',timer:null,file:''}
												 _this.states.BlogTab = 'published'
											}
										})
									}
								  }
								}).catch(e=>{
									_this.$notify.info({title: '消息',message: '上传已暂停'});
								})
								
							}else{
								_this.$notify({title: '提示',message: '请把选择视频源！',type: 'warning'});
								return false
							}
						}else{
							_this.$notify({title: '提示',message: '请把上传封面！',type: 'warning'});
							return false
						}
					}else{
						_this.$notify({title: '提示',message: '请把Blog基本信息填写完整！',type: 'warning'});
						return false
					}
				})
			},
		}
	}
</script>

<style>

	.chapter {
		width: 100%;
		height: 48px;
		background-color: #AAE0FC;
		padding: 0 15px;
		line-height: 48px;
	}

	.section {
		border-top: 1px solid #FFFFFF;
		width: 100%;
		height: 38px;
		padding: 0 65px;
		line-height: 38px;
		background-color: #E2F3FD;
		cursor: pointer;
	}

	.section-child {
		width: 100%;
		min-height: 58px;
		background-color: #EFF9FF;
		padding: 5px 15px;
	}

	.section-p {
		margin: 5px;
	}

	.section-pp {
		margin: 8px;
		font-size: 13px;
	}


	#FM .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 130px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}

	#FM .avatar {
		width: 130px;
		height: 80px;
		display: block;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}


	/* 上传 */
	.upload-type-switch {
		text-align: center;
	}



	.upload {
		padding: 30px 50px;
	}

	.progress {
		font-size: 14px;
	}

	.progress i {
		font-style: normal;
	}

	.upload-type {
		color: #666;
		font-size: 12px;
		padding: 10px 0;
	}

	.upload-type button {
		margin: 0 10px 0 20px;
	}

	.status {
		font-size: 14px;
		margin-left: 30px;
	}

	.info {
		font-size: 14px;
		padding-left: 30px;
	}

	/* 文件选择 */
	.upload-demo {
		display: flex;
	}

	.el-list-enter-active,
	.el-list-leave-active {
		transition: none;
	}

	.el-list-enter,
	.el-list-leave-active {
		opacity: 0;
	}

	.el-upload-list {
		height: 40px;
	}

	.uploadImg {
		z-index: 9999;
		width: 300px;
		height: 190px;
		border: 1px #e5e5e5 dashed;
		line-height: 190px;
		text-align: center;
		position: relative;
	}

	.uploadImg-img {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0
	}

	.uploadImg:hover {
		border-color: blue;
		cursor: pointer;
	}

	/* input */
</style>
