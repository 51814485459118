<template>
  <div style="padding:0; width: 100%;">
    <!-- 创建课程 -->
    <div v-if="states.createstate == 1">
      <el-alert :closable="false" title="课程基本信息" style="margin-bottom: 10px;" />
        <el-form ref="ruleForm" :model="states.ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm" size="mini">
              <el-form-item label="课程名称" prop="coursename">
                <el-input v-model="states.ruleForm.coursename" />
              </el-form-item>
              <el-form-item label="授课老师" prop="courseteacher">
                <el-input v-model="states.ruleForm.courseteacher" />
              </el-form-item>
			  <el-form-item label="课程类别" prop="coursecategory">
				<el-select v-model="states.ruleForm.coursecategory" placeholder="请选择课程的类别">
				    <el-option v-for="item in states.CategoryData" :key="item.classID" :label="item.className" :value="String(item.classID)"> </el-option>
				  </el-select>
			  </el-form-item>
			  <el-form-item label="课程介绍" prop="courseintroduce">
				<el-input v-model="states.ruleForm.courseintroduce" type="textarea" />
			  </el-form-item>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="课程封面"><span>标准比例为:1.61:1</span>
                  <div class="uploadImg" @click="uploadImg1" v-loading="loading1">点击添加图片
                    <img :src="states.ruleForm.coverimg" class="uploadImg-img" v-if="states.ruleForm.coverimg">
                  </div>
                </span>
                <input v-show="false" ref="input1" type="file" @change="handlechange">
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="课程海报"><span>标准比例为:1.61:1</span>
                <div class="uploadImg" @click="uploadImg2" v-loading="loading2">点击添加图片
                  <img class="uploadImg-img" :src="states.ruleForm.courseimg" v-if="states.ruleForm.courseimg">
                </div>
                <input v-show="false" ref="input2" type="file" @change="handlechange1">
              </el-form-item>
            </el-col>
          <el-form-item label="是否为付费课程" label-width="140px">
            <el-switch v-model="states.ruleForm.paycourse" />
          </el-form-item>
		  <el-form-item v-if="states.ruleForm.paycourse" label="课程价格" label-width="100px">
			<el-input v-model="states.ruleForm.pricecourse" style="width: 300px;" />
		  </el-form-item>
          <el-form-item v-if="!states.ruleForm.paycourse" label="可观看最高权限" prop="type" label-width="150px">
            <el-radio-group v-model="states.ruleForm.type">
              <el-radio :label="0"><b style="color: #396A4C;">公开课</b></el-radio>
              <el-radio :label="1"><b style="color: #73AD3E;">线上课程</b></el-radio>
              <el-radio :label="2"><b style="color: #F0973D;">社群课程</b></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">创建课程</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
    </div>
	
	

    <!-- 创建课程大纲 -->
    <div v-if="states.createstate == 2">
      <el-alert :closable="false" title="创建课程大纲" />
		<el-table :data="[states.newcourse]" size="mini">
		    <el-table-column prop="coursename" label="课程名称"></el-table-column>
		    <el-table-column prop="category" label="课程分类"></el-table-column>
			<el-table-column prop="teacher" label="课程讲师"></el-table-column>
			<el-table-column prop="date" label="创建时间">
				<template slot-scope="scope">
					{{ Time(scope.row.shelvestime*1000) }}
				</template>
			</el-table-column>
			<el-table-column prop="introduce" label="课程介绍"></el-table-column>
			<el-table-column prop="date" label="课程封面">
				<template slot-scope="scope">
					<el-image style="width: 50px; height: 30px" :src="scope.row.posters" :preview-src-list="[scope.row.posters]"></el-image>
				</template>
			</el-table-column>
			<el-table-column prop="date" label="课程海报">
				<template slot-scope="scope">
					<el-image style="width: 50px; height: 30px" :src="scope.row.thumbnail" :preview-src-list="[scope.row.thumbnail]"></el-image>
				</template>
			</el-table-column>
		</el-table>
      <el-alert :closable="false" title="创建课程章节">
		  <el-button type="success" plain style="position: absolute;right: 15px;top: 6px;" size="mini" @click="complete(states.coursechild)">发布</el-button>
	  </el-alert>
      <el-row style="padding: 15px 10px;">
		  <span style="padding: 0px 15px; color: #666;"><b> 说明：建章后在进行节数的编辑，章节顺序会进行动态排序.</b></span>
		  <el-button type="primary" @click="addChapter" style="float: right;" size="mini">添加一章</el-button>
      </el-row>
      <!-- 章节 -->
      <div v-for="(list, index) in states.coursechild" :key="index" style="border: 1px solid #EEEEEE;">
        <div class="chapter">
          <span style="color: #333;">第{{ index+1 }}章</span>
          <span style="float: right;margin-right: 25px;">
            <el-button type="danger" @click="delChapter(index)" size="mini">删除本章</el-button>
            <el-button type="success" @click="addSection(index)" size="mini">添加一节</el-button>
          </span>
        </div>
		
        <div v-for="(iten, indexs) in list" :key="indexs">
          <div class="section" @click.self="iten.show = !iten.show">
            <span><i class="el-icon-caret-right" /> 第{{ indexs+1 }}节</span>
            <span style="position: absolute; right: 10px;">
              <el-button type="success" size="mini" round @click="editorSection(iten)">编辑</el-button>
              <el-button type="danger" size="mini" round @click="delSection(index,indexs)">删除</el-button>
            </span>
          </div>
          <transition name="el-zoom-in-top">
              <el-row v-if="iten.show" class="section-child">
				<el-table :data="[iten]" size="mini">
				    <el-table-column prop="title" label="小节标题"></el-table-column>
				    <el-table-column prop="intro" label="小节简介"></el-table-column>
					<el-table-column label="视频原名">
						<template slot-scope="scope">
							{{scope.row.source.name}}
						</template>
					</el-table-column>
					<el-table-column prop="intro" label="视频时长">
						<template slot-scope="scope">
							{{scope.row.source.timer}}
						</template>
					</el-table-column>
				</el-table>
				<el-col :span="6" style="padding: 15px;text-align: center;"  v-if="iten.cover" >
					<el-image style="width: 200px; height: 120px" :src="iten.cover" :preview-src-list="[iten.cover]"></el-image>
				</el-col>
				<el-col :span="6" style="padding:5px 15px;text-align: center;"  v-if="iten.source.url" >
					<video :src="iten.source.url" controls="controls" width="200px" height="133px"/>
				</el-col>
              </el-row>
          </transition>
        </div>
      </div>

    </div>
	
	
    
	<!-- 上传 -->
    <div v-if="states.createstate == 3">
      <el-alert :closable="false" title="发布课程">
		  <el-button type="success" plain style="position: absolute;right: 15px;top: 6px;" size="mini" @click="states.createstate = 2">返回</el-button>
	  </el-alert>
        <div v-for="(list,index) in states.coursechild" :key="index">
          <div v-for="(item,idx) in list" :key="idx" style="height: 180px; padding: 15px;border-bottom: 1px solid #EEEEEE;margin-bottom: 5px;">
                <el-col :span="15">
					<el-col :span="24">
						<el-tag type="danger">{{ index+1 }} 章 {{ idx+1 }} 节</el-tag> {{ item.title }}
					</el-col>
					<el-col :span="24" style="height: 120px;">
					  <p style="margin: 3px;padding-bottom: 6px;font-size: 16px;font-weight: 600;">小节说明</p>
					  <p style="margin: 3px;font-size: 14px;">&nbsp;&nbsp;&nbsp;&nbsp; {{ item.intro }}</p>
					</el-col>
					<el-col :span="24" style="text-align: right;padding-right: 15px;">
						<span style="float: left;">{{(item.source.statusText * 100).toFixed(2)}}%</span> 视频时长：{{item.source.timer}}
					</el-col>
					<el-col :span="24">
						<el-progress :percentage="Number(item.source.statusText * 100)" :status="item.source.statusText == 1?'success':'warning'"></el-progress>
					</el-col>
                </el-col>
                <el-col :span="9">
                  <div style="background-color: #000000; color: #FFFFFF; padding:3px 5px; text-align: center;">
                    <p style="margin: 5px;font-size: 14px;">{{ item.source.name }}</p>
                    <video :src="item.source.url" controls="controls" width="250px" height="140px" />
                  </div>
				  
                </el-col>
          </div>
        </div>
        <UploadAuth />
    </div>
	<!-- 课程节 信息编辑弹框 -->
	<el-dialog title="课程编辑信息-节" :visible.sync="dialogTableVisible" class="updateVideo">
		<p style="padding:0px 25px 25px 0px;">
			
			<el-form label-width="100px" size="mini">
				<el-form-item label="本节标题">
				   <el-input v-model="e.title" placeholder="请输入内容" />
				</el-form-item>
				<el-form-item label="本节简介">
				   <el-input v-model="e.intro" type="textarea" :rows="2" placeholder="请输入内容" />
				</el-form-item>
				<el-form-item label="视频封面">
						<el-col :span="12" id="FM">
							<el-col :span="24" v-loading="loading3">
								<el-upload class="avatar-uploader" :before-upload="UpRules" action="#" :show-file-list="false" :http-request="upload" :data="{data:e}">
									<img v-if="e.cover" :src="e.cover" class="avatar" style="width: 130px; height: 80px;">
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
							</el-col>
						</el-col>
						<el-col :span="12" v-if="e.cover">
							<el-image style="width: 130px; height: 80px;border: 1px solid #EEEEEE;" :src="e.cover" :preview-src-list="[e.cover]"></el-image>
							<p style="padding: 0px;margin: 0px;">视频封面</p>
						</el-col>
				</el-form-item>
				<el-form-item label="视频上传">
				   <input id="ids" type="file" class="getImgUrl_file" @change="preview">
				</el-form-item>
				<el-form-item label="操作">
				   <el-button type="success" @click="dialogTableVisible = false">保存</el-button>
				</el-form-item>
			</el-form>
		</p>
	</el-dialog>
	<video v-show="false" id="audio_id" src="" /><!-- 用于拿视频时间 -->
  </div>
</template>

<script>
import {timestampToTime} from '@/utils/fun'
import {PostEditorFile} from '@/utils/request'
import UploadAuth from './UploadAuth'
import { mapGetters} from 'vuex'
export default {
  components: {
    UploadAuth
  },
  computed: {
    ...mapGetters(['states']),
  },
  data() {
    return {
      e: '',
      i: '',
      dialogAbstract: '', // 节 标题
      dialogInstru: '', // 节 简介
      dialogTableVisible: false,
      rules: {
        coursename: [{required: true,message: '请输入课程名称',trigger: 'blur'},{min: 1,max: 50,message: '长度在 1 到 50 个字符',trigger: 'blur'}],
        courseteacher: [{required: true,message: '请写上老师的名称',trigger: 'blur'},{min: 1,max: 10,message: '长度在 1 到 50 个字符',trigger: 'blur'}],
        coursecategory: [{required: true,message: '请选择课程的类别',trigger: 'change'}],
        courseintroduce: [{required: true,message: '请编辑课程介绍',trigger: 'blur'},{ min: 1,max: 2000,message: '长度在 1 到 500 个字符',trigger: 'blur'}],
        type: [{required: true,message: '请至少选择一个权限组',trigger: 'change'}]
      },
	  loading1:false,
	  loading2:false,
	  loading3:false,
    }
  },
  mounted(){

  },
  methods: {
	Time(e){
		return timestampToTime(e)
	},
	// 课程创建
	submitForm(formName) {
		let _this = this
	  this.$refs[formName].validate((valid) => {
	    if (valid) {
	      this.$store.dispatch('createcourse').then(res=>{
			  _this.states.newcourse = res.data
			  _this.states.createstate = 2
		  })
	    } else {
	      this.$message({                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
	        message: '请把课程基本信息填写完整！',
	        type: 'warning'
	      })
	      return false
	    }
	  })
	},
	upload(event){
		this.loading3 = true
		let name = 'cover_' + new Date().getTime()+(event.file.type.split("/")[1] == 'jepg'?'.jpg':'.png')
		this.$PostFile(event.file,name).then(response=>{
			event.data.data.cover = response.data.data
			this.loading3 = false
		})
		setTimeout(() =>{
		    this.loading3 = false
		},4000);
	},
	//上传文件过滤限制
	UpRules(file) {
		const isLt2M = file.size / 1020 / 1020 < 1.9;
		if (!isLt2M) {
			this.$message({message: '封面不得超过2MB',type: 'warning'})
		}
		return isLt2M;
	},
    // 编辑课程大纲
    // 信息开关  e标识：章的序列号  i标识：节的序列号
    sectionChild(n,e, i) {
      n[e][i].splice(0, 1, !n[e][i][0])
    },
    // 添加一章
    addChapter() {
      this.states.coursechild.push([])
    },
    // 删除本章
    delChapter(e) {
      this.states.coursechild.splice(e, 1)
    },
    // 删除本节
    delSection(e, i) {
      this.states.coursechild[e].splice(i, 1)
    },
    // 添加一节
    addSection(e) {
      this.states.coursechild[e].push({show:true,title:'',intro:'',cover:'',source:{file:'',url:'',name:'',timer:0,newName:'',statusText:0}})
    },
    // 编辑本节
    editorSection(e) {
      this.e = e
	  this.dialogTableVisible = true
    },
    // 选择视频
    preview() {
      if (document.getElementById('ids').files[0]) {
        this.e.source.file = document.getElementById('ids').files[0]
		this.e.source.url = this.getObjectURL(document.getElementById('ids').files[0])
		this.e.source.name = document.getElementById('ids').files[0].name
        // 获取视频大小
        this.getvideotime(document.getElementById('ids').files[0]).then(res=>{
			 this.e.source.timer = res
		})
      }
    },
    getObjectURL(file) {
      let url = null
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file)
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file)
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file)
      }
      return url
    },
    // 完成编辑
    complete(e) {
		if(e.length > 0){
			let arr = []
			e.forEach(item=>{
				item.forEach(list=>{
					if(list.title && list.intro && list.cover && list.source.url){
					}else{
						arr.push(list)
					}
				})
			})
			if(arr.length == 0){
				this.states.createstate = 3
			}else{
				this.$notify({title: '提示',message: '请完善信息',showClose: true,type:'warning'});
			}
		}else{
			this.$notify({title: '提示',message: '请完善信息',showClose: true,type:'warning'});
		}
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 获取每个视频的播放时长
    getvideotime(file) {
		return new Promise((resolve, reject) => {
			const url = URL.createObjectURL(file)
			const videos = document.getElementById('audio_id')
			videos.src = url
			videos.oncanplay = function() {
			  let timer = 0
			  timer = Number(videos.duration)
			  timer = timer.toFixed(0)
			  const minute = Math.floor(timer / 60)
			  const seconds = Math.floor(timer % 60)
			  timer = `${minute}分${seconds}秒`
			  resolve(timer)
			}
		})
    },
    // 上传图片
    uploadImg1() {
      this.$refs.input1.click()
    },
    uploadImg2() {
      this.$refs.input2.click()
    },
    handlechange(e) {
		console.log("@@",e)
		this.loading1 = true
		let file = e.target.files[0]
		// 后缀名
		const filesuffix = file.name.split('.')[1]
		const pathname = `cover_${new Date().getTime()}.${filesuffix}`
		this.$PostFile(file,pathname).then(res=>{
		  this.loading1 = false
		  this.states.ruleForm.coverimg = res.data.data
		})
    },
    handlechange1(e){
	  this.loading2 = true	
	  let file = e.target.files[0]
      const filesuffix = file.name.split('.')[1]
      const pathname = `courseimg_${new Date().getTime()}.${filesuffix}`
      this.$PostFile(file,pathname).then(res=>{
		 this.loading2 = false
      	 this.states.ruleForm.courseimg = res.data.data
      })
	  
    }
  }
}
</script>

<style>
	.updateVideo{
		
	}
	.chapter {
		width: 100%;
		height: 48px;
		background-color: #AAE0FC;
		padding: 0 15px;
		line-height: 48px;
	}

	.section {
		border-top: 1px solid #FFFFFF;
		width: 100%;
		height: 38px;
		padding: 0 65px;
		line-height: 38px;
		background-color: #E2F3FD;
		cursor: pointer;
	}

	.section-child {
		width: 100%;
		min-height: 58px;
		background-color: #EFF9FF;
		padding: 5px 15px;
	}

	.section-p {
		margin: 5px;
	}

	.section-pp {
		margin: 8px;
		font-size: 13px;
	}


	#FM .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 130px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}

	#FM .avatar {
		width: 130px;
		height: 80px;
		display: block;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}


	/* 上传 */
	.upload-type-switch {
		text-align: center;
	}



	.upload {
		padding: 30px 50px;
	}

	.progress {
		font-size: 14px;
	}

	.progress i {
		font-style: normal;
	}

	.upload-type {
		color: #666;
		font-size: 12px;
		padding: 10px 0;
	}

	.upload-type button {
		margin: 0 10px 0 20px;
	}

	.status {
		font-size: 14px;
		margin-left: 30px;
	}

	.info {
		font-size: 14px;
		padding-left: 30px;
	}

	/* 文件选择 */
.upload-demo {
		display: flex;
	}
	.el-list-enter-active,
	.el-list-leave-active {
		transition: none;
	}

	.el-list-enter,
	.el-list-leave-active {
		opacity: 0;
	}
	.el-upload-list {
		height: 40px;
	}
  .uploadImg{
	  z-index: 9999;
    width:300px;
    height:190px;
    border:1px #e5e5e5 dashed;
    line-height: 190px;
    text-align: center;
    position: relative;
  }
  .uploadImg-img{
    width:100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0
  }
  .uploadImg:hover{
    border-color:blue;
    cursor: pointer;
  }
	/* input */
</style>
