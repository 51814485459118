<template>
	<el-row :style="'background-color: #ffffff;height:'+(states.Height-72)+'px'">
		<el-col :span="24" v-if="JSON.stringify(catItem) == '{}'">
			<el-col :span="24" style="padding: 5px 15px;text-align: right;">
				<el-button type="success" @click="catItem = {className:'分类名称',classTag:[]}" size="mini">创建新分类</el-button>
			</el-col>
			<el-table :data="CategoryData.slice((Page-1)*pagesize,Page*pagesize)" size="mini" :height="states.Height-113">
				<el-table-column prop="classID" label="类别编号" align="center" />
				<el-table-column prop="className" label="分类名称" show-overflow-tooltip />
				<el-table-column label="Icon" align="center" width="60">
					<template slot-scope="scope">
						<el-image style="width: 35px; height: 35px" :src="scope.row.classIcon" :preview-src-list="[scope.row.classIcon]">
							<div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
						</el-image>
					</template>
				</el-table-column>
				<el-table-column prop="instruct" label="分类介绍" show-overflow-tooltip />
				<el-table-column label="分类标签">
					<template slot-scope="scope">
						<el-tag size="mini" type="primary" v-for="(list,idx) in scope.row.classTag" :key="idx" style="margin-left: 5px;">{{list}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="分类状态">
					<template slot-scope="scope">
						<el-tag size="mini" type="primary" style="margin-left: 5px;" v-if="scope.row.state == '0'">上线</el-tag>
						<el-tag size="mini" type="danger" style="margin-left: 5px;" v-if="scope.row.state == '1'">下线</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-tag type="primary" @click="lookEdit(scope.row)" size="small" style="margin-right: 10px;">查看/编辑</el-tag>
					</template>
				</el-table-column>
			</el-table>
			<el-col :span="24" style="text-align: center;padding-top: 5px;">
				<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="Page" :page-sizes="[5,10,20,50]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="CategoryData.length"></el-pagination>
			</el-col>
		</el-col>
		<el-col :span="24" v-else>
			<el-col :span="24" style="padding: 5px 15px;text-align: right;">
				<el-button type="danger" @click="Back" size="mini">返回列表</el-button>
			</el-col>
			<el-col :span="24" style="padding: 5px 45px;">
				<el-form label-width="110px" size="mini">
				  <el-form-item label="专题名称">
				    <el-input v-model="catItem.className" style="width: 500px;"></el-input>
				  </el-form-item>
					<el-form-item label="专题介绍">
					  <el-input v-model="catItem.instruct" type="textarea" :rows="2" style="width: 500px;"></el-input>
					</el-form-item>
					<el-row>
						<el-col :span="6">
							<el-form-item label="Icon">
								<el-upload class="upload-demo" action="#" :show-file-list="false" :http-request="upload" :data="{data:catItem}" v-loading="loading">
								  <el-button size="mini" type="primary">更新Icon</el-button>
								</el-upload>
							</el-form-item>	
						</el-col>
						<el-col :span="6" v-if="catItem.classIcon">
							<el-form-item label="预览">
								<el-image style="width:20px; height: 30px;" :src="catItem.classIcon" :preview-src-list="[catItem.classIcon]"></el-image>
							</el-form-item>	
						</el-col>
					</el-row>
					<el-form-item label="标签添加">
						<el-input v-model="Tag" placeholder="标签名词" style="width: 430px;"></el-input> <el-button type="primary" size="mini" @click="catItem.classTag.push(Tag),Tag = ''">添加</el-button>
					</el-form-item>	
					<el-form-item label="专题标签">
						<el-tag v-for="(item,index) in catItem.classTag" :key="index" size="small" style="margin-left: 15px;" @click="catItem.classTag.splice(index,1)">{{item}}</el-tag>
					</el-form-item>	
					<el-form-item label="专题标签">
						<el-radio v-model="catItem.state" label="0">上线</el-radio>
						<el-radio v-model="catItem.state" label="1">下架</el-radio>
					</el-form-item>	
				  <el-form-item>
				    <el-button type="primary" @click="onSubmit(catItem)" v-if="'classID' in catItem">保存</el-button>
					<el-button type="primary" @click="oncreate(catItem)" v-else>创建</el-button>
				  </el-form-item>
				</el-form>
			</el-col>
		</el-col>
		
		
		
		
	</el-row>
</template>

<script>
	import {ServerIconFile} from '@/utils/request'
	import {
		mapGetters
	} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				loading:false,
				Tag:'',
				Page: 1,
				pagesize: 10,
				CategoryData: [],
				catItem:{},
				log:{}
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				this.$store.dispatch('GetCategoryList').then(res => {
					res.data.forEach(item=>{
						item.classTag = JSON.parse(item.classTag)
					})
					this.CategoryData = res.data
				})
			},
			SizeChange(val) {
				this.pagesize = val
			},
			CurrentChange(val) {
				this.Page = val
			},
			// 编辑
			lookEdit(event){
				this.log = JSON.stringify(event)
				event.state = String(event.state)
				this.catItem = event
			},
			// 返回
			Back(){
				this.init()
				this.catItem = {}
			},
			// 上传Icon
			upload(event){
				let _this = this;
				_this.loading = true
				let name = 'icon_' + new Date().getTime()+(event.file.type === 'image/jpeg'?'.jpg':'.png')
				ServerIconFile(event.file, name).then(response => {
					event.data.data.classIcon = response.data.data
					_this.loading = false
				})
			},
			// 更新
			onSubmit(event){
				this.$store.dispatch('ModifyCategory',{data:event,key:1}).then(res => {
					if(res.code == 200){
						this.init()
						this.catItem = {}
					}
				})
			},
			// 创建
			oncreate(event){
				this.$store.dispatch('ModifyCategory',{data:event,key:2}).then(res => {
					if(res.code == 200){
						this.init()
						this.catItem = {}
					}
				})
			}
		}
	}
</script>

<style>

</style>
