<template>
	<el-col :span="24" class="Main_body">
		<transition name="el-fade-in-linear">
			<recommend v-if="states.selected == 1"></recommend>
			<blogs v-if="states.selected == 2"></blogs>
			<ModifyUserInfo v-if="states.selected == 7"></ModifyUserInfo>
			<chongzhi v-if="states.selected == 8"></chongzhi>
			<balanceMoney v-if="states.selected == 9"></balanceMoney>
			<history v-if="states.selected == 3"></history>
			<mycourses v-if="states.selected == 5"></mycourses>
			<myfavorite v-if="states.selected == 6"></myfavorite>
			<player v-if="states.selected == 999"></player>
			<OnlineStudents v-if="states.selected == 10"></OnlineStudents>
			<CommunityStudents v-if="states.selected == 11"></CommunityStudents>
			<UpdateCourse v-if="states.selected == 1000"></UpdateCourse>
			<UserAll v-if="states.selected == 1001"></UserAll>
			<UpBlog v-if="states.selected == 1002"></UpBlog>
			<category v-if="states.selected == 1003"></category>
			<classCourse v-if="states.selected >= 10000"></classCourse>
		</transition>
	</el-col>
</template>

<script>
	import recommend from '@/components/Title/recommend' 			  	 		// 首页推荐
	import player from '@/components/player/index' 			  					// 播放器
	import blogs from '@/components/Title/blogs' 			  			 		// 博客
	import classCourse from '@/components/classCourse/index' 					// 课程分类
	import history from '@/components/extra/history' 			  				// 观看历史
	import mycourses from '@/components/extra/mycourses' 			  			// 我的课程
	import myfavorite from '@/components/extra/myfavorite' 			  			// 我的收藏
	import ModifyUserInfo from '@/components/User/ModifyUserInfo'        		// 用户信息修改
	import chongzhi from '@/components/User/chongzhi' 			         		// 充值
	import balanceMoney from '@/components/User/balanceMoney' 			 		// 余额细名
	import OnlineStudents from '@/components/approve/OnlineStudents' 			// 线上学员
	import CommunityStudents from '@/components/approve/CommunityStudents' 		// 社群学员
	
	import UpdateCourse from '@/views/course/course/index' 						// 课程上传
	import UserAll from '@/views/course/users/index' 							// 用户管理
	import UpBlog from '@/views/blog/index' 									// UpBlog
	import category from '@/views/category/index' 								// 课程分类管理
	
	import {mapGetters} from 'vuex'
	export default {
		components: {
			recommend:recommend,
			blogs:blogs,
			ModifyUserInfo: ModifyUserInfo,
			chongzhi:chongzhi,
			balanceMoney:balanceMoney,
			history:history,
			mycourses:mycourses,
			myfavorite:myfavorite,
			player:player,
			OnlineStudents:OnlineStudents,
			CommunityStudents:CommunityStudents,
			UpdateCourse:UpdateCourse,
			UserAll:UserAll,
			UpBlog:UpBlog,
			category:category,
			classCourse:classCourse
		},
		computed: {
			...mapGetters(['states'])
		},
		 watch: {
		    "$store.state.selected"(newval, olval) {
				if(newval != 0){
					this.show()
					this.cla()
					
				}
		    },
		},
		data() {
			return {
				score:5
			}
		},
		mounted() {
			let arr = []
			this.$store.dispatch('GetCategoryList').then(res => {
				res.data.forEach(item=>{
					item.classTag = JSON.parse(item.classTag)
					if(item.state == 0){
						arr.push(item)
					}
				})
				this.states.CategoryData = arr
				this.score = 5
			})
		},
		methods: {
			cla(){
				if(this.score == 0){
					let arr = []
					this.$store.dispatch('GetCategoryList').then(res => {
						res.data.forEach(item=>{
							item.classTag = JSON.parse(item.classTag)
							if(item.state == 0){
								arr.push(item)
							}
						})
						this.states.CategoryData = arr
						this.score = 5
					})
				}
			},
			show(){
				if(localStorage.getItem('userinfo')){
					let uinfo = JSON.parse(localStorage.getItem('userinfo'))
					this.$store.dispatch('verify',{token:uinfo.token}).then(result=>{
						if(result.code == 200){
							this.$store.dispatch('OTHERINFO')   // 用户额外信息
						}else if(result.code == 300){
							localStorage.clear(); //清空本地缓存
						}
					})	
				}
				this.score --
			}
		}
	}
</script>

<style>
	.Main_body{
		width: 100%;
		height: 100%;
		/* background: url('../../../assets/course.png') no-repeat; */
		background-position:center;
	}
</style>
