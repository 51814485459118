<template>
    <div class='commentslist'>
           <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="course_id"
        label="课程id"
        width="90" align="center">
      </el-table-column>
      <el-table-column
        prop="coursename"
        label="课程名称"
        width="120" align="center">
      </el-table-column>
      <el-table-column
        prop="username"
        label="用户名"
         width="120" align="center">
      </el-table-column>
        <el-table-column
        prop="content"
        label="评论信息"
        width="250" align="center">
      </el-table-column>
         <el-table-column
        prop="datetime"
        label="评论时间" align="center">
      </el-table-column>
        <el-table-column
        label="操作"  align="center">
        <template slot-scope="scope">
             <el-button type='primary' size='mini' @click='editpublish(scope.row.comments_id)'>发布</el-button>
             <el-button type='danger'  size='mini' @click='editdelete(scope.row.comments_id)'>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>
</template>
<script>
    export default {
      data() {
        return {
            // 评论审核信息
          tableData: []
        }
      },
      created(){
        //   获取未审核的评论
          this.getcomments()
      },
      methods:{
        //   type 0为课程 1为课件
          getcomments(){
              this.axios.post('https://dsvi.com:3010/management/getauditcomments',{
                  type:0
              })
              .then(res=>{
                  if(res.data.info==200){
                      this.tableData=res.data.data
                  }
              })
          },
        //   发布
        editpublish(id){
            this.axios.post('https://dsvi.com:3010/management/pushcomments',{
                 type:0,
                 id
            })
            .then(res=>{
                if(res.data.info==200){
                    this.$message.success('发布成功')
                    this.getcomments()
                }                
            })
        },
        // 删除
        editdelete(id){
             this.axios.post('https://dsvi.com:3010/management/deletecommentbook',{
                id,
            }).then(res=>{
                if(res.data.info==200){
                    this.getcomments(0)
                    this.$message.success('删除评论成功')
                }
            })
        }
      }
    }
  </script>
<style scoped>

</style>