<template>
	<el-row id="USERALL">
		<!-- <h3 style="line-height: 30px; padding-left: 30px;margin-bottom: 0px;"><i class="el-icon-user"></i> 用户管理</h3> -->
		<el-col :span="24">
			<el-tabs v-model="active">
				<el-tab-pane label="用户列表" name="user">
					<userlist></userlist>
				</el-tab-pane>
			</el-tabs>
		</el-col>
	</el-row>
</template>
<script>
	import userlist from './child/userlist'
	export default {
		components: {
			userlist: userlist
		},
		data() {
			return {
				active: 'user'
			}
		},
		created() {

		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style>
	#USERALL {
		padding: 0px 10px;
		background-color: #fff;
	}
</style>
