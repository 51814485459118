<template>
	<div class='course-vue-play'>
		<video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
			:options="playerOptions" @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @ended="onPlayerEnded($event)"
            @loadeddata="onPlayerLoadeddata($event)"
            @waiting="onPlayerWaiting($event)"
            @playing="onPlayerPlaying($event)"
            @timeupdate="onPlayerTimeupdate($event)"
            @canplay="onPlayerCanplay($event)"
            @canplaythrough="onPlayerCanplaythrough($event)"
            @ready="playerReadied"
            @statechanged="playerStateChanged($event)" v-if="show"></video-player>
	</div>
</template>
<script>
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				playerOptions: {
					playbackRates: [0.5,0.7, 1.0, 1.5, 2.0], // 播放速度
					autoplay: false, 					 // 是否一开始就播放
					muted: false, 						 // 是否一开始就静音
					loop: false, 						 // 循环播放
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "", // 视频格式
						src: ""   // 视频地址
					}],
					poster: "",   // 封面地址
					// width: document.documentElement.clientWidth, //播放器宽度
					notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true,
						durationDisplay: true,
						remainingTimeDisplay: false,
						fullscreenToggle: true, //全屏按钮，
					}
				},
				timeer: 0,
				show:true
			}

		},
		mounted() {
			this.playerOptions.sources[0].src = this.$ip()+this.states.playerduring.respath
			this.playerOptions.poster = this.states.playerduring.cover
		},
		methods: {
			// 父子函数
			playerOn(){
				this.$refs.videoPlayer.player.play();
			},
			// 准备播放
			playerReadied(player) {
				let t = localStorage.getItem('trademrk'+this.states.playerduring.trademark_id)
				if(t && t != 'undefined'){
					player.currentTime(t-1)
				}
			},
			 //监听播放
			onPlayerPlay (player) {
				// 初次鉴权
				this.$store.dispatch('authCourse',{course_id:this.states.playerduring.course_id,trademark_id:this.states.playerduring.trademark_id,uid:this.states.userinfo.id}).then(res=>{
					if(res.code == 200){
						if(localStorage.getItem('trademrk'+this.states.playerduring.trademark_id)){
							this.states.recordPlay = localStorage.getItem('trademrk'+this.states.playerduring.trademark_id)
							setTimeout(() =>{
								this.states.recordPlay = null
							},3000);
						}
						this.$refs.videoPlayer.player.play();
					}else if(res.code == 300){
						this.show = false
						this.$notify({title: '温馨提示',message: res.message,type: 'warning'});
						this.$refs.videoPlayer.player.pause();
						this.playerOptions.poster = this.states.playerduring.cover
						this.$nextTick(() => {
							this.show = true
						})
					}
				})
			},
			//监听暂停
			onPlayerPause (player) {
				console.log("暂停");
			},
			//监听播放状态改变
			// 播放状态   1s ===5
			playerStateChanged($event){
				this.timeer ++
			   if(this.timeer===24){
				   localStorage.setItem('trademrk'+this.states.playerduring.trademark_id,$event.timeupdate);
				   this.$store.dispatch('authCourseIng',{course_id:this.states.playerduring.course_id,trademark_id:this.states.playerduring.trademark_id,uid:this.states.userinfo.id,timeupdate:$event.timeupdate}).then(res=>{
					   if(res.code == 200){
						   this.states.userinfo = res.data
						   localStorage.setItem('userinfo', JSON.stringify(res.data));
					   }else if(res.code == 300){
							this.$notify({title: '温馨提示',message: res.message,type: 'warning'});
							this.$refs.videoPlayer.player.pause();
					   }
				   })
				   this.timeer=0
			   }
			},
			// 监听媒体是否已到达结尾，播放完
			onPlayerEnded (player) {
				localStorage.removeItem('trademrk'+this.states.playerduring.trademark_id)
				// 判断是否存在下一集   冷却5秒继续播放下一集  如果没有下一集 关闭播放器 弹出学习完成
			},
			//DOM元素上的readyState更改导致播放停止。
			onPlayerWaiting (player) {
				// console.log(player);
				// console.log(player,"2播放完");
			},
			// 媒体不再被禁止播放，并且已开始播放。
			onPlayerPlaying (player) {
				// console.log(player);
				// this.$refs.videoPlayer.player.pause();
			},
			// 当播放器在当前播放位置下载数据时触发
			onPlayerLoadeddata (player) {
				// console.log(player);
				// console.log(player,"4播放完");
			},
			//当前播放位置发生变化时触发。
			onPlayerTimeupdate (player) {
				// console.log(player);
				// console.log(player,"5播放完");
			},
			//媒体的readyState为HAVE_FUTURE_DATA或更高
			onPlayerCanplay(player) {
				// console.log('player Canplay!', player)
				// console.log(player,"6播放完");
			},
			//媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
			onPlayerCanplaythrough(player) {
				// console.log('player Canplaythrough!', player)
				// console.log(player,"7播放完");
			}
			
						
		}
	}
</script>
<style scoped>

</style>
