<template>
	<div>
		<el-table :data="UserData.slice((Page-1)*pagesize,Page*pagesize)" size="mini" :height="states.Height-168">
			<el-table-column prop="id" label="序号" width="60" align="center" />
			<el-table-column label="头像" align="center" width="60">
				<template slot-scope="scope">
					<el-image style="width: 35px; height: 35px" :src="scope.row.avatarurl"
						:preview-src-list="[scope.row.avatarurl]">
						<div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
					</el-image>
				</template>
			</el-table-column>
			<el-table-column prop="nickname" label="昵称" width="120px" show-overflow-tooltip />
			<el-table-column prop="ds_name" label="用户名" width="120px" show-overflow-tooltip />
			<el-table-column prop="loginip" label="登录IP" / width="120px">
				<el-table-column label="账户余额">
					<template slot-scope="scope">{{scope.row.money.toFixed(2)}} 元</template>
				</el-table-column>
				<el-table-column label="登录时间" width="122px">
					<template slot-scope="scope">{{Time(scope.row.lasttime*1000)}}</template>
				</el-table-column>
				<el-table-column prop="ctime" label="注册时间" width="122px">
					<template slot-scope="scope">{{Time(scope.row.ctime*1000)}}</template>
				</el-table-column>
				<el-table-column prop="learning" label="学时累计">
					<template slot-scope="scope">{{(scope.row.learning/60/60).toFixed(2)}} 小时</template>
				</el-table-column>
				<el-table-column prop="Due" label="学员到期">
					<template slot-scope="scope">
						<span v-if="scope.row.Due">{{Time(scope.row.Due*1000).split(" ")[0]}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="invitecode" label="邀请码" />
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<el-tag type="primary" @click="lookEdit(scope.row)" size="small" style="margin-right: 10px;">
							查看/编辑</el-tag>
					</template>
				</el-table-column>
		</el-table>
		<el-col :span="24" style="text-align: center;padding-top: 5px;">
			<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="Page"
				:page-sizes="[5,10,20,50]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="UserData.length"></el-pagination>
		</el-col>

		<!-- 弹出框 详细信息 -->
		<el-dialog :visible.sync="dialog" custom-class="UserModify" :show-close="false" :before-close="close">
			<el-button type="primary" @click.native="dialog = false ,user = {}" size="mini" style="position: absolute;right: 10px;z-index: 999;">返回</el-button>
			<el-tabs v-model="active">
			    <el-tab-pane label="用户信息" name="user">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.users}}
					</el-col>
				</el-tab-pane>
			    <el-tab-pane label="用户地址" name="address">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.address}}
					</el-col>
				</el-tab-pane>
			    <el-tab-pane label="用户订单" name="balance">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.balance}}
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="消费记录" name="details">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.details}}
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="历史播放" name="latelyPlay">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.lately_play}}
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="学习时长" name="learningtime">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.learningtime}}
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="用户收藏" name="mycourse">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.mycourse}}
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="用户支付" name="pay">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.pay}}
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="用户课程" name="shopCourse">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.shopCourse}}
					</el-col>
				</el-tab-pane>
				<el-tab-pane label="用户行为" name="systemLog">
					<el-col :span="24" style="height:80vh;overflow: auto;">
						{{user.systemLog}}
					</el-col>
				</el-tab-pane>
			  </el-tabs>
		
			
			<!--信息  -->
	<!-- 		<el-form ref="ruleForm" :model="ruleForm" status-icon label-width="100px" class="demo-ruleForm">
				<el-form-item label="名称:" prop="text">
					<el-input v-model="ruleForm.username" autocomplete="off" />
				</el-form-item>
				<el-form-item label="手机号:" prop="text">
					<el-input v-model="ruleForm.phone" autocomplete="off" />
				</el-form-item>

				<el-form-item label="会员:" prop="text">
					<div>
						<el-radio v-model="ruleForm.huiyuan" :label="1">普通会员</el-radio>
						<el-radio v-model="ruleForm.huiyuan" :label="2">高级会员</el-radio>
						<el-radio v-model="ruleForm.huiyuan" :label="3">社区会员</el-radio>
					</div> -->
					<!-- <el-input  v-model="ruleForm.huiyuan" autocomplete="off"></el-input> -->
			<!-- 	</el-form-item>

				<el-form-item label="积分:" prop="text">
					<el-input v-model="ruleForm.jifen" autocomplete="off" />
				</el-form-item>
				<el-form-item label="余额:" prop="title">
					<el-input v-model="ruleForm.yue" autocomplete="off" />
				</el-form-item> -->
				<!-- 课程 -->
				<!-- <el-form-item label="我的课程:" prop="title">
					<el-collapse accordion>
						<el-collapse-item title="点击查看"> -->
							<!-- 课件表格 -->
							<!-- <el-table :data="ruleForm.course" style="width: 100%">
								<el-table-column prop="course_id" label="id" width="100" />
								<el-table-column prop="coursename" label="名称" width="180" />
								<el-table-column label="分类">
									<template slot-scope="scope">
										<el-tag type="success">{{ getCategory1(scope.row) }}</el-tag>
									</template>
								</el-table-column>
								<el-table-column label="操作" align="center">
									<template slot-scope="scope">
										<el-tag type="success">暂无</el-tag>
										<el-tag type="info" @click="handledelelte(scope.row)">删除</el-tag>
									</template>
								</el-table-column>
							</el-table>
						</el-collapse-item>
					</el-collapse>
				</el-form-item> -->
				<!-- 课件 -->
				<!-- <el-form-item label="我的课件:" prop="title">
					<el-collapse accordion>
						<el-collapse-item title="点击查看"> -->
							<!-- 课件表格 -->
							<!-- <el-table :data="ruleForm.coursebook" style="width: 100%">
								<el-table-column prop="coursebook_id" label="id" width="100" />
								<el-table-column prop="coursename" label="名称" width="180" />
								<el-table-column label="分类">
									<template slot-scope="scope">
										<el-tag type="success">{{ getCategory(scope.row) }}</el-tag>
									</template>
								</el-table-column>
								<el-table-column label="操作" align="center">
									<template slot-scope="scope">
										<el-tag type="success">暂无</el-tag>
										<el-tag type="info" @click="handledeleltebook(scope.row)">删除</el-tag>
									</template>
								</el-table-column>
							</el-table>
						</el-collapse-item>
					</el-collapse>
				</el-form-item>
				<el-form-item label="学习时长:" prop="title">
					<el-input v-model="ruleForm.studytime" autocomplete="off" />
				</el-form-item>
				<el-form-item> -->
					<!-- <el-button type="primary" @click="submitUser()">修改</el-button> -->
					<!-- <el-button type="primary" @click="dialog = false ,user = {}">返回</el-button>
				</el-form-item>
			</el-form> -->
		</el-dialog>
	</div>
</template>
<script>
	import {
		timestampToTimes
	} from '@/utils/fun'
	import {
		mapGetters
	} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				Page: 1,
				pagesize: 10,
				UserData: [],
				dialog: false, 		// 用户详情窗口
				user: {}, 			// 用户详情
				active:'user'
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			Time(e) {
				return timestampToTimes(e)
			},
			// 初始化
			init() {
				this.$store.dispatch('GetUserAll').then(res => {
					this.UserData = res.data
				})
			},
			SizeChange(val) {
				this.pagesize = val
			},
			CurrentChange(val) {
				this.Page = val
			},
			close() {},
			// 查看/编辑
			lookEdit(e) {
				this.$store.dispatch('GetUser', {
					uid: e.id
				}).then(res => {
					this.user = res.data
					this.dialog = true
				})
			},
			// 修改提交表单
			submitUser() {

			}
		}
	}
</script>
<style>
	.el-tag {
		cursor: pointer;
	}
	.UserModify {
		padding:10px 15px;
		width: 90%;
		height: 90%;
		margin-top: 3vh !important;
	}
</style>
