<template>
	<el-row id="mycourse" v-loading="loading" element-loading-text="拼命加载中" element-loading-background="rgba(0, 0, 0, 0.8)">
		<el-col :span="24" style="padding: 0px 25px;color: #dcdcdc;"> <i class="el-icon-date"></i> 我的课程</el-col>
		<el-col :span="24" style="padding: 5px 25px;">
			<el-col :span="6" v-for="(item,index) in mycourse" :key="index" class="box" @click.native="OpenCourese(item)">
				<el-col :span="24" style="box-shadow: 0px 2px 5px rgba(9, 9, 9, 0.5);">
					<div class="demo-image__placeholder">
					    <div class="block">
						  <el-image :src="item.thumbnail" fit="contain"></el-image>
						</div>
					</div>
					<h5>{{item.coursename}}</h5>
					<p class="studyers">
						<span style="color: #666;">学习人数：{{item.studyers}} 人 · {{timestampToDate(item.shelvestime)}}</span>
						<span class="permis" v-if="item.types == 'kc'">
							<span style="color: #11cbf0;" v-if="item.permis == 0" title="免费公开课">公开课程</span>
							<span style="color: #04ce08;" v-if="item.permis == 1" title="线上课程">线上课程</span>
							<span style="color: #ff521e;" v-if="item.permis == 2" title="社群学员专享">社群课程</span>
							<span style="color: #ffa508;" v-if="item.permis == 3" title="付费课程">付费课程</span>
						</span>
					</p>
				</el-col>
			</el-col>
		</el-col>
	</el-row>
</template>

<script>
	import {timestampToDate} from '@/utils/fun'
	import {mapGetters} from 'vuex'
	export default {
		computed: {
			...mapGetters(['states'])
		},
		data() {
			return {
				mycourse:[],
				loading:false
			}
		},
		mounted() {
			this.init()
		},

		methods: {
			OpenCourese(e){
				if(JSON.stringify(this.states.userinfo) != '{}'){
					this.states.ispermis = false
					this.$store.dispatch('courseInfo',{course_id:e.course_id,uid:this.states.userinfo.id}).then(res=>{
						if(res.code == 200){
							this.states.playerSource = {course:e,chapter:this.OCURSECONTENT(res.data)}
							this.states.playerduring = this.OCURSECONTENT(res.data).chapter[0][0]
							this.states.ispermis = res.ispermis
							this.states.selected = 999
						}else{
							this.$notify({ title: '温馨提醒',message: '您还没获取学习本门课程的资格呢！',type: 'warning'});
						}
					})
				}else{
					this.$notify({ title: '温馨提醒',message: '请您先登录！',type: 'warning'});
				}
			},
			// 格式化课程章节表
			OCURSECONTENT(e){
				let arraysay = [],arr = [],cat = [],arrine = [],obj = {}
				e.forEach(item=>{
					cat.push(item.chapter)
				})
				arrine = Array.from(new Set(cat))
				//播放时长数组
				let timelengths = [],o = 0;
				arrine.forEach(item=>{
					e.forEach(list=>{
						if(list.chapter == item){
							list.show = false
							timelengths.push(list.timelength.split(/[分秒]/))
							arr.push(list)
							o ++;
						}
					})
					arraysay.push(arr)
					arr = []
				})
				let suour = 0
				//计算章节数  
				obj.chapternumber = arraysay.length
				obj.sectionnumber = o
				//计算时长总和
				timelengths.forEach(item=>{
					suour += parseInt(item[0]*60) + parseInt(item[1]===''?0:item[1])	
				})
				suour = suour/60/60
				// 总时长
				obj.timezong = suour.toFixed(2)
				obj.chapter = arraysay
				return obj
			},
			timestampToDate(e){
				return timestampToDate(e*1000)
			},
			init(){
				// 获取我的课程
				this.loading = true
				this.$store.dispatch('GetMyCourses').then(res=>{
					if(res.code == '200'){
						this.mycourse = res.data
						this.loading = false
					}
				})
			}
		}
	}
</script>

<style>
	#mycourse {
		padding: 15px;
		height: calc(100vh - 72px);
	}
	#mycourse h5{
		width: 100%;
		margin: 0px;
		padding: 4px 10px;
		color: #f3f3f3;
		font-size: 14px;
	}
	#mycourse h5:hover{
		color: #FF6716;
	}
	#mycourse .box{
		padding: 15px;
		cursor: pointer;
	}
	#mycourse .permis{
		float: right;
	}
	#mycourse .studyers{
		line-height: 15px;
		padding: 0px 10px;
		padding-bottom: 5px;;
		font-size: 12px;
		color: #efefef;
		margin: 0px;
	}
</style>
